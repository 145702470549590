export const getStudent = /* GraphQL */ `
  query GetStudent($id: ID!) {
    getStudent(id: $id) {
      email
    }
  }
`;

export const getRegistration = /* GraphQL */ `
  query GetRegistration($id: ID!) {
    getRegistration(id: $id) {
      id
      studentId
      student {
        email
      }
    }
  }
`;

export const registrationsByCourseIdAndLastName = /* GraphQL */ `
  query RegistrationsByCourseIdAndLastName(
    $courseId: ID!
    $lastName: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelRegistrationFilterInput
    $limit: Int
    $nextToken: String
  ) {
    registrationsByCourseIdAndLastName(
      courseId: $courseId
      lastName: $lastName
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
	  items {
        id
        courseId
        studentId
        student{
          firstName
          lastName
          email
          dob
          pronouns
          phoneMobile
          address1
        }
        createdAt
        lastName
		status
        sub
        updatedAt
      }
      nextToken
    }
  }
`;

export const attendancesByClassId = /* GraphQL */ `
  query AttendancesByClassId(
    $classId: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelAttendanceFilterInput
    $limit: Int
    $nextToken: String
  ) {
    attendancesByClassId(
      classId: $classId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        classId
        studentId
        student{
          firstName
          lastName
        }
        createdAt
        status
		registrationStatus
        updatedAt
      }
      nextToken
    }
  }
`;