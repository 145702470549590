export const updateCourse = /* GraphQL */ `
  mutation UpdateCourse(
    $input: UpdateCourseInput!
    $condition: ModelCourseConditionInput
  ) {
    updateCourse(input: $input, condition: $condition) {
      id
      instructorId
      instructor {
        id
        firstName
		lastName
        createdAt
        updatedAt
      }
      termId
      term {
        id
        name
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
      createdBy
      updatedBy
      registrationSlug
      firstClassDate
      classRepeatCount
      classRepeatInterval
      classRepeatType
      classDayTimes
      isOpenArtsOrTuition
      tuitionAmount
      openArtsConfig
      image
      imageDescription
      registrationOpenTime
      description
      supplyList
      confirmationLinks
      confirmationNotes
      dayOfWeek
      endDate
      medium
      name
      numAvailable
      numRegistered
      numWaitlisted
      skillLevel
      startDate
      status
      studio
      timeOfDay
      allCourses
      location
      ageLevel
      numSeats
      showInSearch
    }
  }
`;

export const createAttendance = /* GraphQL */ `
  mutation CreateAttendance(
    $input: CreateAttendanceInput!
    $condition: ModelAttendanceConditionInput
  ) {
    createAttendance(input: $input, condition: $condition) {
      id
    }
  }
`;

export const updateAttendance = /* GraphQL */ `
  mutation UpdateAttendance(
    $input: UpdateAttendanceInput!
    $condition: ModelAttendanceConditionInput
  ) {
    updateAttendance(input: $input, condition: $condition) {
      id
    }
  }
`;