import Settings from './Settings.vue'

const config = {
	mainRoute: {
		name: "Settings",
		path: "/settings",
		component: Settings,
	},
	nav: {
		name: "Settings",
		to: "RootCourses",
		position: 4,
		show: true
	},
	module: "settings",
	auth: ['admin']
}

export { config }