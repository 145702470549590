import { Route } from "@/types" // must be imported since file contains an export statement
import { config } from "./config"

export const route: Route[] = [
	// main route
	{
		path: config.mainRoute.path,
		name: config.mainRoute.name,
		component: config.mainRoute.component,
		meta: {
			module: config.module,
			auth: config.auth
		}
	},
	// course detail route
	{ 
		path: config.courseDetailRoute.path,
		name: config.courseDetailRoute.name,
		component: config.courseDetailRoute.component,
		props: config.courseDetailRoute.props,
		meta: {
			module: config.module,
			auth: config.auth
		}
	}
];
