import CourseSearch from "./CourseSearch.vue"
import CourseRegistrationDetails from "./CourseRegistrationDetails.vue"

const config = {
	mainRoute: {
		name: "CourseSearch",
		path: "/course-search",
		component: CourseSearch
	},
	courseDetailRoute: {
		name: "CourseRegistrationDetails",
		path: "/course-search/:courseId",
		component: CourseRegistrationDetails,
		props: true
	},
	nav: {
		name: "Course Catalog",
		to: "CourseSearch",
		position: 1,
		show: true		
	},
	module: "courseCatalog",
	auth: ['student', 'instructor', 'public'] 
}

export { config }
