export const getStudent = /* GraphQL */ `
  query GetStudent($id: ID!) {
    getStudent(id: $id) {
      id
      address1
      address2
      city
      country
      dob
      education
      email
      ethnicity
      firstName
      gender
      income
      isInstructor
      lastActivity
      lastLogin
      lastName
      middleName
      notes
      phoneHome
      phoneMobile
      phoneWork
      phoneWorkExt
      postal
      pronouns
      state
      status
      sub
      allStudents
      createdAt
      updatedAt
    }
  }
`;

export const registrationsByStudentIdAndCreatedAt = /* GraphQL */ `
  query RegistrationsByStudentIdAndCreatedAt(
    $studentId: ID!
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelRegistrationFilterInput
    $limit: Int
    $nextToken: String
  ) {
    registrationsByStudentIdAndCreatedAt(
      studentId: $studentId
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        courseId
        course{
          name
          status
          startDate
          endDate
          dayOfWeek
          timeOfDay
          medium
          skillLevel
          studio
          numRegistered
          numWaitlisted
          numAvailable
        }
		status
		
      }
      nextToken
    }
  }
`;

export const emailsByStudentId = /* GraphQL */ `
  query EmailsByStudentId(
    $studentId: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelemailFilterInput
    $limit: Int
    $nextToken: String
  ) {
    emailsByStudentId(
      studentId: $studentId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        body
        sentAt
        status
        subject
      }
      nextToken
    }
  }
`;