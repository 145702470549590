import { defineStore } from 'pinia'
import { useStorage } from '@vueuse/core'

export const appliedFiltersStore = defineStore('appliedFilters', {
	// a function that returns a fresh state
	state: () => ({
		appliedFilters: useStorage('appliedFilters', {
			courseSearch: ['', [], [], [], []]
		})
	}),
	
	// optional getters
	getters: {

	},

	// optional actions
	actions: {

	}
})