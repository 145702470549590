import { Route } from "@/types" // must be imported since file contains an export statement
import { config } from "./config"
import ContactInfo from './components/ContactInfo.vue'
import EmailPassword from './components/EmailPassword.vue'
// import Notes from './components/Notes.vue'
import PersonalInfo from './components/PersonalInfo.vue'
import RelatedAccounts from './components/RelatedAccounts.vue'


export const route: Route[] = [
	// main route
	{
		path: config.mainRoute.path,
		name: config.mainRoute.name,
		component: config.mainRoute.component,
		meta: {
			module: config.module
        },
		children: [
			{
				name: 'EmailPassword',
				path: '/my-account/email-password',
				component: EmailPassword,
				meta: {
					module: config.module,
					title: "Email & Password"
				},
			},
			{
				name: 'PersonalInfo',
				path: '/my-account/personal-information',
				component: PersonalInfo,
				meta: {
					module: config.module,
					title: "Personal Information"
				},
			},
			{
				name: 'ContactInfo',
				path: '/my-account/contact-information',
				component: ContactInfo,
				meta: {
					module: config.module,
					title: "Contact Information"
				},
			},
			{
				name: 'RelatedAccounts',
				path: '/my-account/related-accounts',
				component: RelatedAccounts,
				meta: {
					module: config.module,
					title: "Related Accounts"
				},
			},
		],
    }
];
