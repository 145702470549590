
import { listSystems } from '../queries'
// amplify
import { Auth, API } from 'aws-amplify'
import api from '@/utilities/api'

interface Card {
	expYear: string,
	expMonth: string,
	address: {
		region: string,
		postalCode: string,
		streetAddress: string,
		city: string
	},
	name: string,
	cvc: string,
	number: string
}

function generateUUID() {
  return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, c => {
    const r = (Math.random() * 16) | 0;
    const v = c === 'x' ? r : (r & 0x3) | 0x8;
    return v.toString(16);
	})
}

async function refreshOauthIfExpired(force=false) {
	const myInit = {
		headers: {
			Authorization: `Bearer ${(await Auth.currentSession())
				.getIdToken()
				.getJwtToken()}`
		},
		body: {
			force: force
		}
	}
	await API.post("quickbooks", "/quickbooks/refresh", myInit)
}

async function getOauthURL(redirectUri: string) {

	try {
		const myInit = {
			headers: {
				Authorization: `Bearer ${(await Auth.currentSession())
					.getIdToken()
					.getJwtToken()}`
			},
			body: {
				redirectUri: redirectUri
			}
		}
		const res = await API.post("quickbooks", "/quickbooks/getOauthUrl", myInit)


		const { oauthURL } = res
		return oauthURL
	} catch (e) {
		console.error("There was an error fetching the oauth URL", e)
	}
}

async function createCharge(
	card: Card
) {
	await refreshOauthIfExpired(true)
	
	// get token from db
	const listSys:any = await api(listSystems)
	const system = listSys?.data?.listSystems.items[0]
	const oauthToken = JSON.parse(system?.value?.qbOauthToken || "{}")
	
	try {
		const requestId = generateUUID()
		//eslint-disable-next-line
		const charge = await fetch(String(process.env.VUE_APP_QUICKBOOKS_PAYMENT_TOKEN_ENDPOINT), {
			method: 'POST',
			headers: {
				"Content-Type": "application/json",
				"Authorization": `Bearer ${oauthToken.access_token}`,
				"Request-Id": requestId,
			},
			body: JSON.stringify({
				card
			})
		})
		const status = charge.ok
		const token = await charge.json()
		
		if(!status) {
			// create error object and reject if not a 2xx response code
			const err:any = new Error("HTTP status code: " + charge.status)
			err.response = charge
			err.status = charge.status
			console.error(err)
			throw token?.errors[0]?.message
		}

		return token
	} catch (e) {
		console.error("There was an issue creating the charge", e)
		throw("There was an issue creating the charge: " + e)
	}
}

export { getOauthURL, createCharge, Card, refreshOauthIfExpired }
