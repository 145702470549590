export const listSelectLists = /* GraphQL */ `
	query ListSelectLists {
		listSelectLists {
			items {
				category
				id
				sortIndex
				name
				key
				options {
					items {
						label
						sortIndex
						selectListId
						id
					}
				}
			}
		}
	}

`;

export const listSystems = /* GraphQL */ `
  query ListSystems(
    $id: ID
    $filter: ModelSystemFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listSystems(
      id: $id
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        id
        confirmationIntroduction
        confirmationCancellationPolicy
		confirmationWaitlist
		courseSearchOpening
		courseSearchTitle
		qbOauthToken
		qbAccounts
		qbItems
		qbDonationItem
		qbDepositToAccount
		qbClasses
		qbDonationItemClass
      }
      nextToken
    }
  }
`;

export const allRootCoursesSortName = /* GraphQL */ `
  query AllRootCoursesSortName(
    $allRootCourses: Int!
    $name: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelRootCourseFilterInput
    $limit: Int
    $nextToken: String
  ) {
    allRootCoursesSortName(
      allRootCourses: $allRootCourses
      name: $name
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        latestTermId
        latestTermName
        medium
        name
        skillLevel
      }
      nextToken
    }
  }
`;
export const allRootCoursesSortTerm = /* GraphQL */ `
  query AllRootCoursesSortTerm(
    $allRootCourses: Int!
    $latestTermName: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelRootCourseFilterInput
    $limit: Int
    $nextToken: String
  ) {
    allRootCoursesSortTerm(
      allRootCourses: $allRootCourses
      latestTermName: $latestTermName
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
		id
        latestTermId
        latestTermName
        medium
        name
        skillLevel
      }
      nextToken
    }
  }
`;

export const getRootCourse = /* GraphQL */ `
  query GetRootCourse($id: ID!) {
    getRootCourse(id: $id) {
      id
      courses(sortDirection: DESC) {
		items {
			id
			name
			status
			term {
				name
			}
			dayOfWeek
			timeOfDay
			studio
			numRegistered
		}
        nextToken
      }
      createdBy
      updatedBy
      isOpenArtsOrTuition
      tuitionAmount
      openArtsConfig
      image
      imageDescription
      description
      supplyList
      medium
      name
      skillLevel
      studio
      location
      ageLevel
      createdAt
      updatedAt
    }
  }
`;

export const getCourse = /* GraphQL */ `
  query GetCourse($id: ID!) {
    getCourse(id: $id) {
      id
      instructorId
      termId
      assistantId
      rootCourseId
      createdAt
      updatedAt
      createdBy
      updatedBy
      registrationSlug
      firstClassDate
      classRepeatCount
      classRepeatInterval
      classRepeatType
      classDayTimes
      isOpenArtsOrTuition
      tuitionAmount
      openArtsConfig
      image
      imageDescription
      registrationOpenTime
      description
      supplyList
      confirmationLinks
      confirmationNotes
      dayOfWeek
      endDate
      medium
      name
      numAvailable
      numRegistered
      numWaitlisted
      numDropped
      numTransferred
      skillLevel
      startDate
      status
      studio
      timeOfDay
      suggestedContribution
      allCourses
      location
      ageLevel
      numSeats
      showInSearch
      donationAmount
      minimumRegistrations
    }
  }
`;

export const listTerms = /* GraphQL */ `
  query ListTerms(
    $id: ID
    $filter: ModelTermFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listTerms(
      id: $id
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        id
        name
		startDate
		endDate
		isActive
      }
      nextToken
    }
  }
`;

export const byInstructor = /* GraphQL */ `
  query ByInstructor(
    $isInstructor: Int!
    $firstName: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelStudentFilterInput
    $limit: Int
    $nextToken: String
  ) {
    byInstructor(
      isInstructor: $isInstructor
      firstName: $firstName
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        firstName
        lastName
        pronouns
		dob
		email
		sub
      }
      nextToken
    }
  }
`;

export const getTerm = /* GraphQL */ `
  query GetTerm($id: ID!) {
    getTerm(id: $id) {
      id
      createdAt
      endDate
      name
      startDate
      isActive
      updatedAt
    }
  }
`;