export const exportByType = /* GraphQL */ `
  query ExportByType(
    $type: String!
    $name: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelExportFilterInput
    $limit: Int
    $nextToken: String
  ) {
    exportByType(
      type: $type
      name: $name
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        type
        createdAt
        exportTable
        fieldMapping
        file
        name
        query
        recordIdList
        status
        user
        updatedAt
      }
      nextToken
    }
  }
`;