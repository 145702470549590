export const createSystem = /* GraphQL */ `
  mutation CreateSystem(
    $input: CreateSystemInput!
    $condition: ModelSystemConditionInput
  ) {
    createSystem(input: $input, condition: $condition) {
      id
      confirmationIntroduction
      confirmationCancellationPolicy
	  courseSearchTitle
	  courseSearchOpening
      createdAt
      updatedAt
    }
  }
`;
export const updateSystem = /* GraphQL */ `
  mutation UpdateSystem(
    $input: UpdateSystemInput!
    $condition: ModelSystemConditionInput
  ) {
    updateSystem(input: $input, condition: $condition) {
      id
      confirmationIntroduction
      confirmationCancellationPolicy
	  courseSearchTitle
	  courseSearchOpening
      createdAt
      updatedAt
    }
  }
`;

export const createCourse = /* GraphQL */ `
  mutation CreateCourse(
    $input: CreateCourseInput!
    $condition: ModelCourseConditionInput
  ) {
    createCourse(input: $input, condition: $condition) {
      id
    }
  }
`;

export const createSelectListOption = /* GraphQL */ `
  mutation CreateSelectListOption(
    $input: CreateSelectListOptionInput!
    $condition: ModelSelectListOptionConditionInput
  ) {
    createSelectListOption(input: $input, condition: $condition) {
      id
      selectListId
      sortIndex
      value
      label
      deleted
      createdAt
      updatedAt
    }
  }
`;
export const deleteSelectListOption = /* GraphQL */ `
  mutation DeleteSelectListOption(
    $input: DeleteSelectListOptionInput!
    $condition: ModelSelectListOptionConditionInput
  ) {
    deleteSelectListOption(input: $input, condition: $condition) {
      id
      selectListId
      sortIndex
      value
      label
      deleted
      createdAt
      updatedAt
    }
  }
`;
export const updateSelectListOption = /* GraphQL */ `
	mutation UpdateSelectListOption(
		$input: UpdateSelectListOptionInput!
		$condition: ModelSelectListOptionConditionInput
	) {
		updateSelectListOption(input: $input, condition: $condition) {
			id
			selectListId
			sortIndex
			value
			label
			deleted
			createdAt
			updatedAt
		}
	}
`;

export const createRootCourse = /* GraphQL */ `
  mutation CreateRootCourse(
    $input: CreateRootCourseInput!
    $condition: ModelRootCourseConditionInput
  ) {
    createRootCourse(input: $input, condition: $condition) {
      id
    }
  }
`;
export const updateRootCourse = /* GraphQL */ `
  mutation UpdateRootCourse(
    $input: UpdateRootCourseInput!
    $condition: ModelRootCourseConditionInput
  ) {
    updateRootCourse(input: $input, condition: $condition) {
      id
    }
  }
`;

export const updateTerm = /* GraphQL */ `
  mutation UpdateTerm(
    $input: UpdateTermInput!
    $condition: ModelTermConditionInput
  ) {
    updateTerm(input: $input, condition: $condition) {
      id
      isActive
    }
  }
`;
export const createTerm = /* GraphQL */ `
  mutation CreateTerm(
    $input: CreateTermInput!
    $condition: ModelTermConditionInput
  ) {
    createTerm(input: $input, condition: $condition) {
      id
      name
      createdAt
      isActive
      startDate
      endDate
      updatedAt
    }
  }
`;

export const deleteTerm = /* GraphQL */ `
  mutation DeleteTerm(
    $input: DeleteTermInput!
    $condition: ModelTermConditionInput
  ) {
    deleteTerm(input: $input, condition: $condition) {
      id
      createdAt
      endDate
      name
      startDate
      isActive
      updatedAt
    }
  }
`;