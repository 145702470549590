import { Route } from "@/types" // must be imported since file contains an export statement
import { config } from "./config"

export const route: Route[] = [
	// main route
    {
		path: config.mainRoute.path,
		name: config.mainRoute.name,
		component: config.mainRoute.component,
		meta: {
			layout: config.mainRoute.layout
		}
    },
	// other routes below
	{
		path: '/reset-password',
		name: 'ResetPassword',
		component: config.mainRoute.component,
		meta: { 
			layout: config.mainRoute.layout
		}
	},
	{
		path: '/reset-password-requested?email=:email',
		name: 'ResetPasswordRequested',
		component: config.mainRoute.component,
		meta: { 
			layout: config.mainRoute.layout
		},
		props: true
	},
];