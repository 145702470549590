import { Route } from "@/types" // must be imported since file contains an export statement
import { config } from "./config"

export const route: Route[] = [
	// main route
    {
		path: config.mainRoute.path,
		name: config.mainRoute.name,
		component: config.mainRoute.component,
		meta: {
			module: config.module,
			auth: config.auth
		}
    },
	// course details route
	{
		path: config.courseDetailsRoute.path,
		name: config.courseDetailsRoute.name,
		component: config.courseDetailsRoute.component,
		meta: {
			module: config.module,
			auth: config.auth
		},
		props: true
    },
	{
		path: "/courses/new-course",
		name: "NewCourse",
		component: config.courseDetailsRoute.component,
		meta: {
			module: config.module,
			auth: config.auth
		}
    },
];