import Courses from "./CoursesMain.vue"
import CourseDetails from "./courseDetails/CourseDetails.vue"

const config = {
	mainRoute: {
		name: "Courses",
		path: "/courses",
		component: Courses
	},
	courseDetailsRoute: {
		name: "CourseDetails",
		path: "/courses/:id/:edit?",
		component: CourseDetails,
    },
	nav: {
		name: "Courses",
		to: "Courses",
		position: 1,
		show: true
	},
	module: "courses",
	auth: ['admin']
}

export { config }