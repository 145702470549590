import { Route } from "@/types" // must be imported since file contains an export statement
import { config } from "./config"
import RootCourses from "./components/RootCourses.vue"
import RootCoursesDetails from "./components/RootCoursesDetails.vue"
import InstructorsSettings from "./components/Instructors.vue"
import DropDownValues from "./components/DropDownValues.vue"
import QuickbooksSettings from "./components/Quickbooks.vue"
import ConfirmationLetter from "./components/ConfirmationLetter.vue"
import Registration from "./components/Registration.vue"
import TermSettings from "./components/Terms.vue"
import Registrations from "./components/RegistrationsMain.vue"
import TermDetails from './components/TermDetails.vue'

export const route: Route[] = [
	// main route
	{
		path: config.mainRoute.path,
		name: config.mainRoute.name,
		component: config.mainRoute.component,
		meta: {
			module: config.module,
			auth: config.auth
		},
		children: [
			{
				name: 'RootCourses',
				path: '/settings/root-courses',
				component: RootCourses,
				meta: {
					module: config.module,
					title: "Root Courses",
					auth: config.auth
				},
			},
			{
				name: 'RootCoursesDetails',
				path: '/settings/root-courses/:id',
				component: RootCoursesDetails,
				meta: {
					module: config.module,
					hide: true,
					auth: config.auth
				},
				props: true
			},		
			{
				name: 'Instructors',
				path: '/settings/instructors-settings',
				component: InstructorsSettings,
				meta: {
					module: config.module,
					title: "Instructors",
					auth: config.auth
				},
			},			
			{
				name: 'DropDownValues',
				path: '/settings/drop-down-values',
				component: DropDownValues,
				meta: {
					module: config.module,
					title: "Drop Down Values",
					auth: config.auth
				},
			},			
			{
				name: 'Quickbooks',
				path: '/settings/quickbooks-settings',
				component: QuickbooksSettings,
				meta: {
					module: config.module,
					title: "Quickbooks",
					auth: config.auth
				},
			},			
			{
				name: 'ConfirmationLetter',
				path: '/settings/confirmation-letter',
				component: ConfirmationLetter,
				meta: {
					module: config.module,
					title: "Confirmation Letter",
					auth: config.auth
				},
			},
			{
				name: 'Registration',
				path: '/settings/registration',
				component: Registration,
				meta: {
					module: config.module,
					title: "Registration",
					auth: config.auth
				},
			},
			{
				name: 'Terms',
				path: '/settings/terms',
				component: TermSettings,
				meta: {
					module: config.module,
					title: "Terms",
					auth: config.auth
				},
			},
			{
				name: 'TermDetails',
				path: '/settings/terms/:id',
				component: TermDetails,
				meta: {
					module: config.module,
					hide: true,
					auth: config.auth
				},
				props: true
			},	
			{
				name: 'RegistrationsMain',
				path: '/settings/registrations',
				component: Registrations,
				meta: {
					module: config.module,
					title: "List of Registrations",
					auth: config.auth
				},
			}
		]
    }
];
