import { Route } from "@/types" // must be imported since file contains an export statement
import { config } from "./config"

export const route: Route[] = [
	// main route
    {
		path: config.mainRoute.path,
		name: config.mainRoute.name,
		component: config.mainRoute.component,
		meta: {
			module: config.module,
			auth: config.auth,
			title: config.mainRoute.title
		}
    },
	// instructor course details
	{
		path: config.instructorCourseDetail.path,
		name: config.instructorCourseDetail.name,
		component: config.instructorCourseDetail.component,
		meta: {
			module: config.module,
			auth: config.auth
		}
    }
	
];