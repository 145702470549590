import { Route } from "@/types" // must be imported since file contains an export statement
import { config } from "./config"

export const route: Route[] = [
	// main route
    {
		path: config.mainRoute.path,
		name: config.mainRoute.name,
		component: config.mainRoute.component,
		meta: {
			module: config.module,
			auth: config.auth
		}
    }
	// other routes below
];