/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "us-east-1",
    "aws_cloud_logic_custom": [
        {
            "name": "courseRegistrationQueue",
            "endpoint": "https://n0qayffxq8.execute-api.us-east-1.amazonaws.com/prod",
            "region": "us-east-1"
        },
        {
            "name": "quickbooks",
            "endpoint": "https://vvxdaf9z8i.execute-api.us-east-1.amazonaws.com/prod",
            "region": "us-east-1"
        }
    ],
    "aws_appsync_graphqlEndpoint": "https://vbdfsj7lbbcpdbrpl3i4a7ntei.appsync-api.us-east-1.amazonaws.com/graphql",
    "aws_appsync_region": "us-east-1",
    "aws_appsync_authenticationType": "AMAZON_COGNITO_USER_POOLS",
    "aws_cognito_identity_pool_id": "us-east-1:42c5f6d0-ac8a-4765-a44a-cd954bb53bc3",
    "aws_cognito_region": "us-east-1",
    "aws_user_pools_id": "us-east-1_CKjO32c3q",
    "aws_user_pools_web_client_id": "7qb2duvs1gb8r248jvh1vlgg2i",
    "oauth": {},
    "aws_cognito_username_attributes": [
        "EMAIL"
    ],
    "aws_cognito_social_providers": [],
    "aws_cognito_signup_attributes": [
        "EMAIL"
    ],
    "aws_cognito_mfa_configuration": "OFF",
    "aws_cognito_mfa_types": [
        "SMS"
    ],
    "aws_cognito_password_protection_settings": {
        "passwordPolicyMinLength": 8,
        "passwordPolicyCharacters": []
    },
    "aws_cognito_verification_mechanisms": [
        "EMAIL"
    ],
    "aws_user_files_s3_bucket": "courseregistrationportal120918-prod",
    "aws_user_files_s3_bucket_region": "us-east-1"
};


export default awsmobile;
