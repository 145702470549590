export const studentBySub = /* GraphQL */ `
  query StudentBySub(
    $sub: String!
    $sortDirection: ModelSortDirection
    $filter: ModelStudentFilterInput
    $limit: Int
    $nextToken: String
  ) {
    studentBySub(
      sub: $sub
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        address1
        address2
        city
        country
        createdBy
        dob
        education
        email
        ethnicity
        firstName
        gender
        income
        isInstructor
        lastActivity
        lastLogin
        lastName
        middleName
        notes
        phoneHome
        phoneMobile
        phoneWork
        phoneWorkExt
        postal
        pronouns
        relationshipToAccount
        state
        status
        sub
        updatedBy
        allStudents
        createdAt
        updatedAt
        emergencyContactFirstName
        emergencyContactLastName
        emergencyContactPhone
      }
      nextToken
    }
  }
`;