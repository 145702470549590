import { resolveComponent as _resolveComponent, createVNode as _createVNode, renderSlot as _renderSlot, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "min-h-screen" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_tw_navbar = _resolveComponent("tw-navbar")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createVNode(_component_tw_navbar),
    _createElementVNode("main", null, [
      _createElementVNode("div", _hoisted_1, [
        _renderSlot(_ctx.$slots, "default")
      ])
    ])
  ]))
}