import Students from './StudentsMain.vue'
import StudentDetails from './studentDetail/StudentDetails.vue'

const config = {
	mainRoute: {
		name: "Students",
		path: "/students",
		component: Students
	},
	studentDetailsRoute: {
		name: "StudentDetails",
		path: "/students/:id",
		component: StudentDetails
	},
	nav: {
		name: "Students",
		to: "Students",
		position: 2,
		show: true,
	},
	module: "students",
	auth: ['admin']
}

export { config }