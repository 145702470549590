import { API, graphqlOperation, Auth} from 'aws-amplify'
import { GRAPHQL_AUTH_MODE } from '@aws-amplify/api'

export default async function (operation: string, variables = {}) {

	let user = null
	let accessToken = null
	// check for logged in user
	try{
		user = await Auth.currentSession()
		accessToken = user.getAccessToken()
	}
	catch(e) {
		console.log(e)
	}

    let result
    try {
		// run query or mutation
		if(user && accessToken) {
			result = await API.graphql(graphqlOperation(operation, variables))
		}
		else {
			result = await API.graphql({
				query: operation,
				variables: variables,
				authMode: GRAPHQL_AUTH_MODE.AWS_IAM
			})
		}
		
    }
    catch(err){
		console.log(err)
		// if logged out, refresh token or log out
		result = err
		throw err
    }

    return result
}