import { API, graphqlOperation, Auth } from 'aws-amplify'
import { GRAPHQL_AUTH_MODE } from '@aws-amplify/api'

/**
 * Loads data until next tokens are exhausted
 * @param {String} query - GraphQL query to invoke
 * @param {Object} input - input variables for GraphQL query
 * @param {String} queryName - name of GraphQL query being invoked
 */
 export default async function async (query, input, queryName){
	let user = null
	let accessToken = null
	// check for logged in user
	try{
		user = await Auth.currentSession()
		accessToken = user.getAccessToken()
	}
	catch(e) {
		console.log(e)
	}

	try {
		let nextToken = null
		let list = []
		do{
			input.nextToken = nextToken
			let response
			try{
				if(user && accessToken) {
					response = await API.graphql(graphqlOperation(query, input))
				}
				else {
					response = await API.graphql({
						query: query,
						variables: input,
						authMode: GRAPHQL_AUTH_MODE.AWS_IAM
					})
				}
			}
			catch(err){
				console.log(err)
				response = err
			}
	
			list = [ ...list, ...response.data[queryName].items]
			nextToken = response.data[queryName].nextToken
		}while(nextToken)
		return list
	}
	catch(e) {
		console.log(e)
	}
  }