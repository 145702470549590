export const relatedStudentsByParentId = /* GraphQL */ `
  query RelatedStudentsByParentId(
    $parentId: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelRelatedStudentFilterInput
    $limit: Int
    $nextToken: String
  ) {
    relatedStudentsByParentId(
      parentId: $parentId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        child {
          id
          firstName
          lastName
          dob
          email
          phoneMobile
		pronouns
		notes
		status
		lastActivity
        }
      }
      nextToken
    }
  }
`;

export const getStudent = /* GraphQL */ `
  query GetStudent($id: ID!) {
    getStudent(id: $id) {
      id
      address1
      address2
      city
      country
      dob
      education
      email
      ethnicity
      firstName
      gender
      income
      isInstructor
      lastActivity
      lastLogin
      lastName
      middleName
      notes
      phoneHome
      phoneMobile
      phoneWork
      phoneWorkExt
      postal
      pronouns
      state
      status
      sub
      allStudents
      createdAt
      updatedAt
    }
  }
`;