import { defineStore } from 'pinia'
import { useStorage } from '@vueuse/core'

export const valueListStore = defineStore('valueList', {
	// a function that returns a fresh state
	state: () => ({
		valueList: useStorage('valueList', {
			income: [],
			medium: [],
			gender: [],
			ethnicity: [],
			pronouns: [],
			skillLevel: [],
			ageLevel: [],
			education: []
		}),

	}),
	
	// optional getters
	getters: {

	},

	// optional actions
	actions: {

	}
})