export const listClasses = /* GraphQL */ `
  query ListClasses(
    $id: ID
    $filter: ModelClassFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listClasses(
      id: $id
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        id
        courseId
        date
        day
        endTime
        startTime
      }
      nextToken
    }
  }
`;
export const classesByDate = /* GraphQL */ `
  query ClassesByDate(
    $date: AWSDate!
    $startTime: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelClassFilterInput
    $limit: Int
    $nextToken: String
  ) {
    classesByDate(
      date: $date
      startTime: $startTime
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        courseId
        course {
		  id
          name
          studio
          startTime
          endTime
        }
        createdAt
        date
        day
        endTime
        startTime
        updatedAt
      }
      nextToken
    }
  }
`;
export const getCourse = /* GraphQL */ `
  query GetCourse($id: ID!) {
    getCourse(id: $id) {
      id
      name
      studio
    }
  }
`;
export const listCourses = /* GraphQL */ `
  query ListCourses(
    $id: ID
    $filter: ModelCourseFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listCourses(
      id: $id
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        id
        endDate
        medium
        name
        numAvailable
        numDropped
        numRegistered
        numSeats
        numWaitlisted
        skillLevel
        startDate
				updatedAt
        studio
				status
				dayOfWeek
				timeOfDay
      }
      nextToken
    }
  }
`;
export const allCoursesSortUpdatedAt = /* GraphQL */ `
  query AllCoursesSortUpdatedAt(
    $allCourses: Int!
    $updatedAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelCourseFilterInput
    $limit: Int
    $nextToken: String
  ) {
    allCoursesSortUpdatedAt(
      allCourses: $allCourses
      updatedAt: $updatedAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        endDate
        medium
        name
        numAvailable
        numDropped
        numRegistered
        numSeats
        numWaitlisted
        skillLevel
        startDate
		updatedAt
        studio
		status
		startTime
		endTime
		dayOfWeek
		timeOfDay
		allCourses
      }
      nextToken
    }
  }
`;
export const listRegistrations = /* GraphQL */ `
  query ListRegistrations(
    $id: ID
    $filter: ModelRegistrationFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listRegistrations(
      id: $id
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        id
        courseId
        studentId
        amount
        createdAt
        createdBy
        lastName
        status
        statusUpdatedAt
        sub
        quickbooksToken
        updatedBy
        allRegistrations
        updatedAt
      }
      nextToken
    }
  }
`;
export const allCoursesSortAvailableCount = /* GraphQL */ `
  query AllCoursesSortAvailableCount(
    $allCourses: Int!
    $numAvailable: ModelIntKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelCourseFilterInput
    $limit: Int
    $nextToken: String
  ) {
    allCoursesSortAvailableCount(
      allCourses: $allCourses
      numAvailable: $numAvailable
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        endDate
        medium
        name
        numAvailable
        numDropped
        numRegistered
        numSeats
        numWaitlisted
        skillLevel
        startDate
		updatedAt
        studio
		status
		startTime
		endTime
		dayOfWeek
		timeOfDay
		allCourses
      }
      nextToken
    }
  }
`;
export const allCoursesSortWaitListCount = /* GraphQL */ `
  query AllCoursesSortWaitListCount(
    $allCourses: Int!
    $numWaitlisted: ModelIntKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelCourseFilterInput
    $limit: Int
    $nextToken: String
  ) {
    allCoursesSortWaitListCount(
      allCourses: $allCourses
      numWaitlisted: $numWaitlisted
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        endDate
        medium
        name
        numAvailable
        numDropped
        numRegistered
        numSeats
        numWaitlisted
        skillLevel
        startDate
		updatedAt
        studio
		status
		startTime
		endTime
		dayOfWeek
		timeOfDay
		allCourses
      }
      nextToken
    }
  }
`;
export const allRegistrationsSortCreatedAt = /* GraphQL */ `
  query AllRegistrationsSortCreatedAt(
    $allRegistrations: Int!
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelRegistrationFilterInput
    $limit: Int
    $nextToken: String
  ) {
    allRegistrationsSortCreatedAt(
      allRegistrations: $allRegistrations
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        courseId
		course {
			name
		}
        studentId
		student {
			id
			firstName
			lastName
		}
        amount
        createdAt
        createdBy
        lastName
        status
		paymentStatus
		qbPaymentStatus
		qbPaymentError
        statusUpdatedAt
        sub
        quickbooksToken
        updatedBy
        allRegistrations
        updatedAt
      }
      nextToken
    }
  }
`;
