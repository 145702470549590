export const listSelectLists = /* GraphQL */ `
  query ListSelectLists(
    $id: ID
    $filter: ModelSelectListFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listSelectLists(
      id: $id
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        id
        key
        name
		options {
			items {
				label
				sortIndex
				selectListId
				id
			}
		}
      }
      nextToken
    }
  }
`;

export const listSystems = /* GraphQL */ `
  query ListSystems(
    $id: ID
    $filter: ModelSystemFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listSystems(
      id: $id
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        id
        confirmationIntroduction
        confirmationCancellationPolicy
        confirmationWaitlist
        courseSearchOpening
        courseSearchTitle
        qbOauthToken
        qbAccounts
        qbClasses
        qbDepositToAccount
        qbDonationItem
        qbDonationItemClass
        qbItems
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;