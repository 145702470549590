import { createRouter, createWebHistory } from 'vue-router'
// import { Route } from '@/types' // must be imported since file contains an export statement
import { Auth } from 'aws-amplify'
import * as VueRouter from 'vue-router'

// Import all of the resource routes files
function loadRoutes() {
    const context = require.context('@/modules', true, /routes.ts$/i)
    return context.keys()
        .map(context) // import module
        .map((m: any) => m.route) // get `default` export from each resolved module
}

// Define the routes
const routes: VueRouter.RouteRecordRaw[] = []
const resourceRoutes = loadRoutes();

resourceRoutes.forEach((moduleRoutes) => {
    moduleRoutes.forEach((route: VueRouter.RouteRecordRaw) => {
        routes.push(route)
    })
});

// Create the router instance and pass the `routes` option
export const router = createRouter({
    history: createWebHistory(), // use history mode
    routes, // short for `routes: routes`,
	scrollBehavior() {
		// always scroll to top
		return { top: 0 }
	},
})

router.beforeEach(async (to: any, from: any, next: any) => {
    const user = await authenticate()

	// check for player profile session
	const groups = user?.signInUserSession?.accessToken?.payload["cognito:groups"]
	const isStudent = groups?.includes('student')
	const isInstructor = groups?.includes('instructor')
	const isAdmin = groups?.includes('admin')

	// prevent student and instructor sessions from accessing other routes
	if((isInstructor) && to.meta?.auth?.includes('admin') && !isAdmin) {
		next({ name: 'InstructorCourses' })
	}
	else if((isStudent) && to.meta?.auth?.includes('admin') && !isAdmin) {
		next({ name: 'CourseSearch' })
	}

	// prevent unathenticated users from accessing other routes besides login or signup
    else if (to.name !== 'Login' && 
		to.name !== 'AdminLogin' && 
		to.name !== 'CreateAccount' && 
		to.name !== 'AccountConfirmation' && 
		to.name !== 'ResetPassword' && 
		to.name !== 'ResetPasswordRequested' && 
		to.name !== 'CourseSearch' && 
		to.name !== 'CourseRegistrationDetails' && 
		!user) {
		next({ name: 'Login', query: { redirect: to.fullPath } })
	}
	// let user through to requested route
    else {
		next()
	}
});

async function authenticate(){
    try {
        const user = await Auth.currentAuthenticatedUser()
        return user
    }
    catch(e) {
        return false
    }
}
