import StudentCoursesMain from "./StudentCoursesMain.vue"

const config = {
	mainRoute: {
		name: "MyCourses",
		path: "/my-courses",
		component: StudentCoursesMain
	},
	nav: {
		name: "My Courses",
		to: "MyCourses",
		position: 2,
		show: true
	},
	module: "mycourses",
	auth: ['student'] 
}

export { config }
