export const updateStudent = /* GraphQL */ `
  mutation UpdateStudent(
    $input: UpdateStudentInput!
    $condition: ModelStudentConditionInput
  ) {
    updateStudent(input: $input, condition: $condition) {
        id
    }
  }
`;

export const createStudent = /* GraphQL */ `
  mutation CreateStudent(
    $input: CreateStudentInput!
    $condition: ModelStudentConditionInput
  ) {
    createStudent(input: $input, condition: $condition) {
      id
    }
  }
`;

export const createRelatedStudent = /* GraphQL */ `
  mutation CreateRelatedStudent(
    $input: CreateRelatedStudentInput!
    $condition: ModelRelatedStudentConditionInput
  ) {
    createRelatedStudent(input: $input, condition: $condition) {
      id
    }
  }
`;