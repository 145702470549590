export const listCourses = /* GraphQL */ `
  query ListCourses(
    $id: ID
    $filter: ModelCourseFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listCourses(
      id: $id
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        instructor {
			firstName
			lastName
		}
        id
        termId
        createdAt
        updatedAt
        createdBy
        updatedBy
        registrationSlug
        firstClassDate
        classRepeatCount
        classRepeatInterval
        classRepeatType
        classDayTimes
        isOpenArtsOrTuition
        tuitionAmount
        openArtsConfig
        image
        imageDescription
        registrationOpenTime
        description
        supplyList
        confirmationLinks
        confirmationNotes
        dayOfWeek
        endDate
        medium
        name
        numAvailable
        numRegistered
        numWaitlisted
        skillLevel
        startDate
        status
        studio
        timeOfDay
        allCourses
        location
        ageLevel
        numSeats
        showInSearch
      }
      nextToken
    }
  }
`;

export const getCourse = /* GraphQL */ `
  query GetCourse($id: ID!) {
    getCourse(id: $id) {
      id
      instructorId
      instructor {
        firstName
		lastName
      }
      assistant {
        firstName
		lastName
      }
      termId
      term {
        name
      }
	  classes {
		items {
			date
			day
			endTime
			startTime
		}
	  }
      firstClassDate
      isOpenArtsOrTuition
      tuitionAmount
      openArtsConfig
      image
      imageDescription
      registrationOpenTime
      description
      supplyList
      confirmationLinks
      confirmationNotes
      dayOfWeek
      endDate
      medium
      name
	  numAvailable
	  numSeats
      skillLevel
      startDate
      status
      studio
      timeOfDay
      location
      ageLevel
	  suggestedContribution
	  startTime
	  endTime
    }
  }
`;

export const coursesByTerm = /* GraphQL */ `
  query CoursesByTerm(
    $termId: ID!
    $name: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelCourseFilterInput
    $limit: Int
    $nextToken: String
  ) {
    coursesByTerm(
      termId: $termId
      name: $name
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
		instructor {
			firstName
			lastName
		}
        id
        isOpenArtsOrTuition
		tuitionAmount
        image
        imageDescription
        registrationOpenTime
        dayOfWeek
        endDate
        medium
        name
        numAvailable
		numSeats
        startDate
        timeOfDay
        ageLevel
		startTime
		endTime
		suggestedContribution
		openArtsConfig
      }
      nextToken
    }
  }
`;

export const activeTerms = /* GraphQL */ `
  query ActiveTerms(
    $isActive: Int!
    $name: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelTermFilterInput
    $limit: Int
    $nextToken: String
  ) {
    activeTerms(
      isActive: $isActive
      name: $name
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        name
      }
      nextToken
    }
  }
`;

export const registrationsByStudentIdAndCourseId = /* GraphQL */ `
  query RegistrationsByStudentIdAndCourseId(
    $studentId: ID!
    $courseId: ModelIDKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelRegistrationFilterInput
    $limit: Int
    $nextToken: String
  ) {
    registrationsByStudentIdAndCourseId(
      studentId: $studentId
      courseId: $courseId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
		id
      }
      nextToken
    }
  }
`;