export const getCourse = /* GraphQL */ `
  query GetCourse($id: ID!) {
    getCourse(id: $id) {
      id
      instructorId
      instructor {
		id
        firstName
        lastName
        pronouns
      }
      termId
      term {
		id
		name
        startDate
      }
      assistantId
      assistant {
		id
        firstName
        lastName
        pronouns
      }
      classes {
		items {
			id
			date
			day
			startTime
			endTime
		}
        nextToken
      }
	  registrations {
		items {
			id
			status
		}
	  }
      rootCourseId
      rootCourse {
        name
      }
      createdAt
      updatedAt
      createdBy
      updatedBy
      registrationSlug
      firstClassDate
      classRepeatCount
      classRepeatInterval
      classRepeatType
      classDayTimes
      isOpenArtsOrTuition
      tuitionAmount
      openArtsConfig
      image
      imageDescription
      registrationOpenTime
      description
      supplyList
      confirmationLinks
      confirmationNotes
      dayOfWeek
      endDate
      medium
      name
      numAvailable
      numRegistered
      numWaitlisted
      numDropped
      numTransferred
      skillLevel
      startDate
      status
      studio
      timeOfDay
      suggestedContribution
      location
      ageLevel
      numSeats
      showInSearch
      donationAmount
      minimumRegistrations
	  qbItem
	  qbClass
    }
  }
`;

export const byInstructor = /* GraphQL */ `
  query ByInstructor(
    $isInstructor: Int!
    $firstName: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelStudentFilterInput
    $limit: Int
    $nextToken: String
  ) {
    byInstructor(
      isInstructor: $isInstructor
      firstName: $firstName
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        firstName
        lastName
        pronouns
		sub
      }
      nextToken
    }
  }
`;

export const listTerms = /* GraphQL */ `
  query ListTerms(
    $id: ID
    $filter: ModelTermFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listTerms(
      id: $id
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        id
        name
      }
      nextToken
    }
  }
`;

export const listRootCourses = /* GraphQL */ `
  query ListRootCourses(
    $id: ID
    $filter: ModelRootCourseFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listRootCourses(
      id: $id
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        id
        name
      }
      nextToken
    }
  }
`;

export const registrationsByCourseIdAndLastName = /* GraphQL */ `
  query RegistrationsByCourseIdAndLastName(
    $courseId: ID!
    $lastName: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelRegistrationFilterInput
    $limit: Int
    $nextToken: String
  ) {
    registrationsByCourseIdAndLastName(
      courseId: $courseId
      lastName: $lastName
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
	  items {
        id
        courseId
        studentId
        student{
          firstName
          lastName
          email
          dob
          pronouns
          phoneMobile
          address1
        }
        createdAt
        lastName
		status
        sub
        updatedAt
      }
      nextToken
    }
  }
`;

export const classesByCourseIdAndDate = /* GraphQL */ `
  query ClassesByCourseIdAndDate(
    $courseId: ID!
    $date: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelClassFilterInput
    $limit: Int
    $nextToken: String
  ) {
    classesByCourseIdAndDate(
      courseId: $courseId
      date: $date
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        courseId
        date
        startTime
        endTime
		attendance {
			items {
				status
			}
		}
      }
      nextToken
    }
  }
`;

export const registrationsByCourseIdAndCreatedAt = /* GraphQL */ `
  query RegistrationsByCourseIdAndCreatedAt(
    $courseId: ID!
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelRegistrationFilterInput
    $limit: Int
    $nextToken: String
  ) {
    registrationsByCourseIdAndCreatedAt(
      courseId: $courseId
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        courseId
        studentId
	   studentName
        student{
		      id
          firstName
          lastName
          email
          dob
          pronouns
          phoneMobile
          address1
        }
        createdAt
        lastName
		    status
        sub
        updatedAt
      }
      nextToken
    }
  }
`;

export const registrationsByCourseIdAndStatus = /* GraphQL */ `
  query RegistrationsByCourseIdAndStatus(
    $courseId: ID!
    $status: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelRegistrationFilterInput
    $limit: Int
    $nextToken: String
  ) {
    registrationsByCourseIdAndStatus(
      courseId: $courseId
      status: $status
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        courseId
        studentId
	   studentName
        student{
		      id
          firstName
          lastName
          email
          dob
          pronouns
          phoneMobile
          address1
        }
        createdAt
        lastName
		    status
        sub
        updatedAt
      }
      nextToken
    }
  }
`;

export const searchStudents = /* GraphQL */ `
  query SearchStudents(
    $filter: SearchableStudentFilterInput
    $sort: [SearchableStudentSortInput]
    $limit: Int
    $nextToken: String
    $from: Int
    $aggregates: [SearchableStudentAggregationInput]
  ) {
    searchStudents(
      filter: $filter
      sort: $sort
      limit: $limit
      nextToken: $nextToken
      from: $from
      aggregates: $aggregates
    ) {
      items {
		id
        email
        firstName
        lastName
		sub
      }
      nextToken
      total
      aggregateItems {
        name
        result {
          ... on SearchableAggregateScalarResult {
            value
          }
          ... on SearchableAggregateBucketResult {
            buckets {
              key
              doc_count
            }
          }
        }
      }
    }
  }
`;

export const registrationsByStudentIdAndCourseId = /* GraphQL */ `
  query RegistrationsByStudentIdAndCourseId(
    $studentId: ID!
    $courseId: ModelIDKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelRegistrationFilterInput
    $limit: Int
    $nextToken: String
  ) {
    registrationsByStudentIdAndCourseId(
      studentId: $studentId
      courseId: $courseId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
		id
      }
      nextToken
    }
  }
`;

export const getStudent = /* GraphQL */ `
  query GetStudent($id: ID!) {
    getStudent(id: $id) {
      id
	  firstName
      lastName
      sub
      email
    }
  }
`;

export const getRegistration = /* GraphQL */ `
  query GetRegistration($id: ID!) {
    getRegistration(id: $id) {
      id
      studentId
      student {
        email
      }
    }
  }
`;

export const listSystems = /* GraphQL */ `
  query ListSystems(
    $id: ID
    $filter: ModelSystemFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listSystems(
      id: $id
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        id
        confirmationCancellationPolicy
        confirmationIntroduction
        qbOauthToken
        qbAccounts
        qbClasses
        qbDepositToAccount
        qbDonationItem
        qbDonationItemClass
        qbItems
      }
      nextToken
    }
  }
`;

export const attendancesByClassId = /* GraphQL */ `
  query AttendancesByClassId(
    $classId: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelAttendanceFilterInput
    $limit: Int
    $nextToken: String
  ) {
    attendancesByClassId(
      classId: $classId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        classId
        studentId
        student{
          firstName
          lastName
        }
        createdAt
        status
        updatedAt
      }
      nextToken
    }
  }
`;