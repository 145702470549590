export const createExport = /* GraphQL */ `
  mutation CreateExport(
    $input: CreateExportInput!
    $condition: ModelExportConditionInput
  ) {
    createExport(input: $input, condition: $condition) {
      id
      name
    }
  }
`;

export const updateExport = /* GraphQL */ `
  mutation UpdateExport(
    $input: UpdateExportInput!
    $condition: ModelExportConditionInput
  ) {
    updateExport(input: $input, condition: $condition) {
		id
        type
        createdAt
        exportTable
        fieldMapping
        file
        name
        query
        recordIdList
        status
        user
        updatedAt
    }
  }
`;