
import Login from './LoginMain.vue'
import AuthLayout from '@/layouts/AuthLayout.vue'

const config = {
	mainRoute: {
		name: "Login",
		path: "/",
		component: Login,
		layout: AuthLayout
	}
}

export { config }