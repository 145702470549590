
import CreateAccount from './CreateAccount.vue'
import AuthLayout from '@/layouts/AuthLayout.vue'

const config = {
	mainRoute: {
		name: "CreateAccount",
		path: "/create-account",
		component: CreateAccount,
		layout: AuthLayout
	}
}

export { config }