/* eslint-disable */
import { createApp } from 'vue'
import App from './App.vue'
import { createPinia } from 'pinia'

import { router } from './router/index'
import './assets/css/index.css'
import '@vueup/vue-quill/dist/vue-quill.snow.css'

// import global components
import DataDisplay from '@/components/DataDisplay/DataDisplay.vue'
import DescriptionListItem from '@/components/DataDisplay/DescriptionListItem.vue'
import TwBadge from '@/components/Badges/TwBadge.vue'
import TwButton from '@/components/Buttons/TwButton.vue'
import TwCheckbox from '@/components/Checkboxes/TwCheckbox.vue'
import TwSelect from '@/components/SelectMenus/TwSelect.vue'
import TwInput from '@/components/InputGroups/TwInput.vue'
import TwConfirmationCode from '@/components/InputGroups/TwConfirmationCode.vue'
import TwTable from '@/components/Tables/TwTable.vue'
import TwLoading from '@/components/Loading/TwLoading.vue'
import TwNavbar from '@/components/Navbar/TwNavbar.vue'
import TwSlider from '@/components/Slider/TwSlider.vue'
import TwDropdown from '@/components/Dropdowns/TwDropdown.vue'
import StudentNotesModal from '@/components/Modals/StudentNotesModal.vue'
import EmailModal from '@/components/Modals/EmailModal.vue'
import AttendanceModal from '@/components/Modals/AttendanceModal.vue'
import EmailDisplayModal from '@/components/Modals/EmailDisplayModal.vue'
import TwFullLoading from '@/components/Loading/FullScreenLoading.vue'
import TwNotification from '@/components/Notifications/TwNotification.vue'
import TwTableDropdown from '@/components/Tables/TwTableDropdown.vue'
import TwTableColumn from '@/components/Tables/TwTableColumn.vue'
import TwTableRow from '@/components/Tables/TwTableRow.vue'
import TwTableHeader from '@/components/Tables/TwTableHeader.vue'
import TwExport from '@/components/Export/TwExport.vue'
import { Amplify } from 'aws-amplify';
import awsconfig from './aws-exports';
Amplify.configure(awsconfig);

Amplify.configure({
	API: {
		endpoints: [
		  {
			name: "courseRegistrationQueue",
			endpoint: process.env.VUE_APP_REGISTRATION_QUEUE_API_ENDPOINT,
		  },
		  {
			name: "quickbooks",
			endpoint: process.env.VUE_APP_QUICKBOOKS_API_ENDPOINT,
		  },
		],
	}
})

// import icons from Tailwind's Hero Icons
import {
    BarsArrowUpIcon,
	UserIcon,
	HomeIcon,
    ExclamationCircleIcon,
	CheckCircleIcon as CheckCircleIcon,
	FlagIcon as SolidFlagIcon,
	PencilIcon,
    PlusSmallIcon,
	UserCircleIcon,
	ChevronDownIcon as ChevronDownIcon,
	CheckIcon,
	EllipsisVerticalIcon,
	MagnifyingGlassIcon,
	DocumentTextIcon,
	BarsArrowDownIcon
} from '@heroicons/vue/24/solid'

import { 
	ChevronDownIcon as ChevronDownIconMini, 
	FunnelIcon as FunnelIconMini, 
	ChevronUpIcon as ChevronUpIconMini,
	ArrowTopRightOnSquareIcon as ArrowTopRightOnSquareIconMini,
	ArrowRightIcon as ArrowRightIconMini,
	XMarkIcon as XMarkIconMini,
	ExclamationTriangleIcon as ExclamationTriangleIconMini,
	CheckCircleIcon as CheckCircleIconMini,
	ArrowPathIcon as ArrowPathIconMini,
	UserIcon as UserIconMini,
	PencilIcon as PencilIconMini
} from '@heroicons/vue/20/solid'

import { 
	XCircleIcon, 
	ChevronRightIcon,
	ChevronLeftIcon,
	XMarkIcon, 
	DocumentIcon,
	SquaresPlusIcon,
	PencilIcon as PencilIconOutline,
	ComputerDesktopIcon,
	TrashIcon,
	ArrowTopRightOnSquareIcon,
	CurrencyDollarIcon,
	UserIcon as UserIconOutline,
	UserCircleIcon as UserCircleIconOutline,
	AcademicCapIcon,
	CalendarDaysIcon,
	ListBulletIcon,
	DocumentArrowUpIcon,
	ClipboardDocumentIcon
} from '@heroicons/vue/24/outline'
// import piniaPluginPersistedstate from 'pinia-plugin-persistedstate'

const pinia = createPinia()
// pinia.use(piniaPluginPersistedstate)

export function extractErrorMessage(e: any){
	var message = ''
	try{
	  	if(e.errors) message = e.errors[0].message
		else if(e.response.data.message) message = e.response.data.message
		else if(e.message) message = e.message
		else if(e.data) message = e.data.message
		else message = e
	}
	catch(syntaxError){
		message = e
	}
	return message
}

/**
 * Capitalize the first letter of each word
 * @param {string} value String value needing to have first letter of each word capitalized
 * @function properCase
 */
export function properCase(value: string){
	return value.replace(/\b\w/g, char => char.toUpperCase())
}

// extend types
declare module '@vue/runtime-core' {
	interface ComponentCustomProperties {
	  $filters: {
		age: (date: string|null|undefined) => number | string,
		formatDate: (date: string|null|undefined) => number | string,
		formatTime: (time: string|null|undefined) => number | string,
		formatTimeShort: (time: string|null|undefined, time2: string|null|undefined) => number | string,
		formatTimestamp: (date: string|null|undefined) => number | string,
		formatTimestampSeconds: (date: string|null|undefined) => number | string,
		formatUSD:(amount: number) => string,
		convertIsoToLocal: (isoString: string|null|undefined, timezone: string) => string | undefined,
		getTime: (date: string|null|undefined) => number | string,
		convertTime: (time: string|null|undefined) => number | string,
	  },
	  badgeColor: (str: string) => string
	}
}

const app = createApp(App)

// Set up global filters
app.config.globalProperties.$filters = {
	age(date: string|null|undefined) {
		if(!date) return ''
		let today = new Date();
		let birthDate = new Date(date)
		let age = today.getFullYear() - birthDate.getFullYear()
		let month = today.getMonth() - birthDate.getMonth()
		// If birthday hasn't happened yet this year => Subtract a year
		if(month < 0 || (month === 0 && today.getDate() < birthDate.getDate())) age--
		return age
	},
	formatDate(date: string|null|undefined){
		if(!date) return ''
		let formattedDate = new Date(date).toLocaleDateString("en-us", {timeZone: 'UTC'})
		return formattedDate
	},
	formatTime(time: string|null|undefined){
		if(!time) return ''
		let split = time.split(":")
		let hour = parseInt(split[0])
		let minute = split[1]
		
		let timeOfDay = hour < 12 ? 'AM' : 'PM'
		hour = hour <= 12 ? hour === 0 ? 12 : hour : hour - 12
		return `${hour}:${minute} ${timeOfDay}`
	},
	formatTimeShort(time: string|null|undefined, time2: string|null|undefined){
		if(!time || !time2) return ''
		let hyphen = '\u2010'
		let split = time.split(":")
		let hour = parseInt(split[0])
		let minute = split[1]
		minute = minute == '00' ? '' : `:${minute}`
		let timeOfDay = hour < 12 ? 'AM' : 'PM'
		hour = hour <= 12 ? hour === 0 ? 12 : hour : hour - 12

		let split2 = time2.split(":")
		let hour2 = parseInt(split2[0])
		let minute2 = split2[1]
		minute2 = minute2 == '00' ? '' : `:${minute2}`
		let timeOfDay2 = hour2 < 12 ? 'AM' : 'PM'
		hour2 = hour2 <= 12 ? hour2 === 0 ? 12 : hour2 : hour2 - 12

		if(timeOfDay == timeOfDay2) {
			return `${hour}${minute} ${hyphen} ${hour2}${minute2} ${timeOfDay}`
		}
		else {
			return `${hour}${minute} ${timeOfDay} ${hyphen} ${hour2}${minute2} ${timeOfDay2}`
		}
		
	},
	formatTimestamp(date: string|null|undefined){
		if(!date) return ''
		let _date = new Date(date)
		let day = _date.getDate()
		let month = _date.getMonth()
		let year = _date.getFullYear()
		let hours = _date.getHours()
		let minutes = String(_date.getMinutes()).padStart(2, "0")

		// Determine AM/PM
		let timeOfDay = hours < 12 ? 'AM' : 'PM'
		
		// Convert to 12 hour time
		hours = hours < 13 ? hours === 0 ? 12 : hours : hours - 12

		// Get full month name from month number
		let newDate = new Date()
		newDate.setMonth(month )
		let monthFull = newDate.toLocaleString([], { month: 'long' })

		return `${monthFull} ${day}, ${year} at ${hours}:${minutes} ${timeOfDay}`		
	},
	formatTimestampSeconds(date: string|null|undefined){
		if(!date) return ''
		let _date = new Date(date)
		let day = _date.getDate()
		let month = _date.getMonth()
		let year = _date.getFullYear()
		let hours = _date.getHours()
		let minutes = String(_date.getMinutes()).padStart(2, "0")
		let seconds = String(_date.getSeconds()).padStart(2, "0")
		let miliseconds = String(_date.getMilliseconds()).padStart(2, "0")

		// Determine AM/PM
		let timeOfDay = hours < 12 ? 'AM' : 'PM'
		
		// Convert to 12 hour time
		hours = hours < 13 ? hours === 0 ? 12 : hours : hours - 12

		// Get full month name from month number
		let newDate = new Date()
		newDate.setMonth(month )
		let monthFull = newDate.toLocaleString([], { month: 'long' })

		return `${monthFull} ${day}, ${year} at ${hours}:${minutes}:${seconds}:${miliseconds} ${timeOfDay}`		
	},
	formatUSD(amount: number) {
        const formatter = new Intl.NumberFormat('en-US', {style: 'currency', currency: 'USD'})
        return formatter.format(amount)
    },
	convertIsoToLocal(isoString: string|null|undefined, timezone: string) {
		if(!isoString) return
		
		const options: Intl.DateTimeFormatOptions = {
		  timeZone: timezone,
		  year: 'numeric',
		  month: '2-digit',
		  day: '2-digit',
		  hour: '2-digit',
		  minute: '2-digit',
		  second: '2-digit',
		  hour12: true
		};
	  
		const localString = new Date(isoString).toLocaleString('en-US', options);
		return localString;
	},
	getTime(date: string|null|undefined){
		if(!date) return ''
		let _date = new Date(date)
		let hours = _date.getHours()
		let minutes = String(_date.getMinutes()).padStart(2, "0")

		// Determine AM/PM
		let timeOfDay = hours < 12 ? 'AM' : 'PM'
		
		// Convert to 12 hour time
		hours = hours < 13 ? hours === 0 ? 12 : hours : hours - 12

		return `${hours}:${minutes} ${timeOfDay}`		
	},
	convertTime(time: string|null|undefined){
		if(!time) return ''
		
		let hours = Number(time.split(":")[0])
		let minutes = time.split(":")[1]

		// Determine AM/PM
		let timeOfDay = hours < 12 ? 'AM' : 'PM'
		
		// Convert to 12 hour time
		hours = hours < 13 ? hours === 0 ? 12 : hours : hours - 12

		return `${hours}:${minutes} ${timeOfDay}`		
	}
}

/**
 * Determines the bagde color based on text string
 * @param {String} str - The text string displayed on the badge
 */
app.config.globalProperties.badgeColor = (str: string) => {
	if(str === "Draft" || str === "No Status" || str === "Dropped" || str === "Payment Failed") {
		return "brand3" // Orange
	}
	else if(str === "Registering" || str === "Returning Student" || str === "Waitlisted") {
		return "brand" // Blue
	}
	else if(str === "Underway" || str === "New Account" || str === "Registered" || str === "Payment Successful") {
		return "brand4" // Green
	}
	else if(str === "Archived" || str === "New Registration" || str === "Transfered") {
		return "brand2" // Purple
	}
	else if(str === "Pending" || str === "Payment Pending") {
		return "yellow" // Purple
	}
	else if(str === "Cancelled") {
		return "red"
	}
	else {
		return ""
	}
}


// Register icons globally
app.component('BarsArrowUpIcon', BarsArrowUpIcon)
app.component('CheckCircleIcon', CheckCircleIcon)
app.component('ChevronDownIconMini', ChevronDownIconMini)
app.component('ChevronUpIconMini', ChevronUpIconMini)
app.component('ChevronRightIcon', ChevronRightIcon)
app.component('ChevronLeftIcon', ChevronLeftIcon)
app.component('ArrowRightIconMini', ArrowRightIconMini)
app.component('SolidFlagIcon', SolidFlagIcon)
app.component('CheckIcon', CheckIcon)
app.component('ExclamationCircleIcon', ExclamationCircleIcon)
app.component('HomeIcon', HomeIcon)
app.component('PencilIcon', PencilIcon)
app.component('PlusSmIcon', PlusSmallIcon)
app.component('UserIcon', UserIcon)
app.component('UserCircleIcon', UserCircleIcon)
app.component('XCircleIcon', XCircleIcon)
app.component('XMarkIcon', XMarkIcon)
app.component('FunnelIconMini', FunnelIconMini)
app.component('MagnifyingGlassIcon', MagnifyingGlassIcon)
app.component('EllipsisVerticalIcon', EllipsisVerticalIcon)
app.component('DocumentTextIconSolid', DocumentTextIcon)
app.component('DocumentIcon', DocumentIcon)
app.component('SquaresPlusIcon', SquaresPlusIcon)
app.component('PencilIconOutline', PencilIconOutline)
app.component('ComputerDesktopIcon', ComputerDesktopIcon)
app.component('TrashIcon', TrashIcon)
app.component('ArrowTopRightOnSquareIcon', ArrowTopRightOnSquareIcon)
app.component('ArrowTopRightOnSquareIconMini', ArrowTopRightOnSquareIconMini)
app.component('BarsArrowDownIcon', BarsArrowDownIcon)
app.component('CurrencyDollarIcon', CurrencyDollarIcon)
app.component('UserIconOutline', UserIconOutline)
app.component('UserCircleIconOutline', UserCircleIconOutline)
app.component('ListBulletIcon', ListBulletIcon)
app.component('CalendarDaysIcon', CalendarDaysIcon)
app.component('AcademicCapIcon', AcademicCapIcon)
app.component('DocumentArrowUpIcon', DocumentArrowUpIcon)
app.component('XMarkIconMini', XMarkIconMini)
app.component('ExclamationTriangleIconMini', ExclamationTriangleIconMini)
app.component('CheckCircleIconMini', CheckCircleIconMini)
app.component('ClipboardDocumentIcon', ClipboardDocumentIcon)
app.component('ArrowPathIconMini', ArrowPathIconMini)
app.component('UserIconMini', UserIconMini)
app.component('PencilIconMini', PencilIconMini)


// In another component use, <HomeIcon/> directly in the template

// Register global components
app.component("data-display", DataDisplay)
app.component("description-list-item", DescriptionListItem)
app.component("tw-badge", TwBadge)
app.component("tw-button", TwButton)
app.component("tw-checkbox", TwCheckbox)
app.component("tw-input", TwInput)
app.component("tw-confirmation-code", TwConfirmationCode)
app.component("tw-loading", TwLoading)
app.component("tw-full-loading", TwFullLoading)
app.component("tw-table", TwTable)
app.component("tw-select", TwSelect)
app.component("tw-navbar", TwNavbar)
app.component("tw-notification", TwNotification)
app.component("tw-table-dropdown", TwTableDropdown)
app.component("tw-table-column", TwTableColumn)
app.component("tw-table-row", TwTableRow)
app.component("tw-table-header", TwTableHeader)
app.component("tw-export", TwExport)
app.component("tw-slider", TwSlider)
app.component("tw-dropdown", TwDropdown)
app.component("student-notes-modal", StudentNotesModal)
app.component("email-modal", EmailModal)
app.component("attendance-modal", AttendanceModal)
app.component("email-display-modal", EmailDisplayModal)

app.use(pinia)
app.use(router)
app.mount('#app')
