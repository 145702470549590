import MyAccount from './MyAccount.vue'

const config = {
	mainRoute: {
		name: "MyAccount",
		path: "/my-account",
		component: MyAccount,
	},
	module: "myAccount"
}

export { config }
