// const STUDENT_EXPORT_FIELDS = [
//     { label: 'Student ID', value: 'id' },
//     { label: 'Street Address', value: 'address1' },
//     { label: 'Address 2', value: 'address2'},
//     { label: 'City', value: 'city' },
//     { label: 'Country', value: 'country' },
//     { label: 'Date of Birth', value: 'dob' },
//     { label: 'Education', value: 'education' },
//     { label: 'Email', value: 'email' },
//     { label: 'Ethnicity', value: 'ethnicity' },
//     { label: 'First Name', value: 'firstName' },
//     { label: 'Gender', value: 'gender' },
//     { label: 'Income', value: 'income' },
//     { label: 'Last Activity', value: 'lastActivity' },
//     { label: 'Last Login', value: 'lastLogin' },
//     { label: 'Last Name', value: 'lastName' },
//     { label: 'Middle Name', value: 'middleName' },
//     { label: 'Notes', value: 'notes' },
//     { label: 'Home Phone', value: 'phoneHome' },
//     { label: 'Mobile Phone', value: 'phoneMobile' },
//     { label: 'Work Phone', value: 'phoneWork' },
//     { label: 'Work Ext', value: 'phoneWorkExt' },
//     { label: 'Zip Code', value: 'postal' },
//     { label: 'Pronouns', value: 'pronouns' },
//     { label: 'State', value: 'state' },
//     { label: 'Status', value: 'status' }
// ]

const STUDENT_EXPORT_FIELDS = [
    { label: 'Student ID', value: 'id', selected: true, availableField: true },
    { label: 'Street Address', value: 'address1', selected: false, availableField: true  },
    { label: 'Address 2', value: 'address2', selected: false, availableField: true },
    { label: 'City', value: 'city', selected: false, availableField: true  },
    { label: 'Country', value: 'country', selected: false, availableField: true  },
    { label: 'Date of Birth', value: 'dob', selected: false, availableField: true  },
    { label: 'Education', value: 'education', selected: false, availableField: true  },
    { label: 'Email', value: 'email', selected: false, availableField: true  },
    { label: 'Ethnicity', value: 'ethnicity', selected: false, availableField: true  },
    { label: 'First Name', value: 'firstName', selected: false, availableField: true  },
    { label: 'Gender', value: 'gender', selected: false, availableField: true  },
    { label: 'Income', value: 'income', selected: false, availableField: true  },
    { label: 'Last Activity', value: 'lastActivity', selected: false, availableField: true  },
    { label: 'Last Login', value: 'lastLogin', selected: false, availableField: true  },
    { label: 'Last Name', value: 'lastName', selected: false, availableField: true  },
    { label: 'Middle Name', value: 'middleName', selected: false, availableField: true  },
    { label: 'Notes', value: 'notes', selected: false, availableField: true  },
    { label: 'Home Phone', value: 'phoneHome', selected: false, availableField: true  },
    { label: 'Mobile Phone', value: 'phoneMobile', selected: false, availableField: true  },
    { label: 'Work Phone', value: 'phoneWork', selected: false, availableField: true  },
    { label: 'Work Ext', value: 'phoneWorkExt', selected: false, availableField: true  },
    { label: 'Zip Code', value: 'postal', selected: false, availableField: true  },
    { label: 'Pronouns', value: 'pronouns', selected: false, availableField: true  },
    { label: 'State', value: 'state', selected: false, availableField: true  },
    { label: 'Status', value: 'status', selected: false, availableField: true  }
]

const REGISTRTATION_EXPORT_FIELDS = [
    { label: 'Registration ID', value: 'id', selected: true, availableField: true },
    { label: 'Amount', value: 'amount', selected: false, availableField: true },
    { label: 'Bill to Address', value: 'billToAddress', selected: false, availableField: true },
    { label: 'Registration Created At', value: 'createdAt', selected: false, availableField: true },
    { label: 'Registration Status', value: 'status', selected: false, availableField: true },
    { label: 'Payment Status', value: 'paymentStatus', selected: false, availableField: true },
    { label: 'Registration Updated At', value: 'updatedAt', selected: false, availableField: true },
    { label: 'Term', value: 'course{term{name}}', selected: false, availableField: true },

	// related course
    { label: 'Course ID', value: 'course{id}', selected: false, availableField: true },
    { label: 'Age Level', value: 'course{ageLevel}', selected: false, availableField: true },
    { label: 'Course Day', value: 'course{dayOfWeek}', selected: false, availableField: true },
    { label: 'Course Start Date', value: 'course{startDate}', selected: false, availableField: true },
    { label: 'Course End Date', value: 'course{endDate}', selected: false, availableField: true },
    { label: 'Is Tuition Based', value: 'course{isOpenArtsOrTuition}', selected: false, availableField: true },
    { label: 'Medium', value: 'course{medium}', selected: false, availableField: true },
    { label: 'Course Name', value: 'course{name}', selected: false, availableField: true },
    { label: 'Number of Seats', value: 'course{numSeats}', selected: false, availableField: true },
    { label: 'Number of Seats Available', value: 'course{numAvailable}', selected: false, availableField: true },
    { label: 'Number of Students Dropped', value: 'course{numDropped}', selected: false, availableField: true },
    { label: 'Number of Students Registered', value: 'course{numRegistered}', selected: false, availableField: true },
    { label: 'Number of Students Transferred', value: 'course{numTransferred}', selected: false, availableField: true },
    { label: 'Number of Students Waitlisted', value: 'course{numWaitlisted}', selected: false, availableField: true },
    { label: 'Skill Level', value: 'course{skillLevel}', selected: false, availableField: true },
    { label: 'Studio', value: 'course{studio}', selected: false, availableField: true },
    { label: 'Suggested Contribution', value: 'course{suggestedContribution}', selected: false, availableField: true },
    { label: 'Tuition Amount', value: 'course{tuitionAmount}', selected: false, availableField: true },
	
	
	// related student
    { label: 'Student ID', value: 'student{id}', selected: false, availableField: true },
    { label: 'Student Street Address', value: 'student{address1}', selected: false, availableField: true  },
    { label: 'Student Address 2', value: 'student{address2}', selected: false, availableField: true },
    { label: 'Student City', value: 'student{city}', selected: false, availableField: true  },
    { label: 'Student Country', value: 'student{country}', selected: false, availableField: true  },
    { label: 'Student Date of Birth', value: 'student{dob}', selected: false, availableField: true  },
    { label: 'Student Education', value: 'student{education}', selected: false, availableField: true  },
    { label: 'Student Email', value: 'student{email}', selected: false, availableField: true  },
    { label: 'Student Ethnicity', value: 'student{ethnicity}', selected: false, availableField: true  },
    { label: 'Student First Name', value: 'student{firstName}', selected: false, availableField: true  },
    { label: 'Student Gender', value: 'student{gender}', selected: false, availableField: true  },
    { label: 'Student Income', value: 'student{income}', selected: false, availableField: true  },
    { label: 'Student Last Activity', value: 'student{lastActivity}', selected: false, availableField: true  },
    { label: 'Student Last Login', value: 'student{lastLogin}', selected: false, availableField: true  },
    { label: 'Student Last Name', value: 'student{lastName}', selected: false, availableField: true  },
    { label: 'Student Middle Name', value: 'student{middleName}', selected: false, availableField: true  },
    { label: 'Student Notes', value: 'student{notes}', selected: false, availableField: true  },
    { label: 'Student Home Phone', value: 'student{phoneHome}', selected: false, availableField: true  },
    { label: 'Student Mobile Phone', value: 'student{phoneMobile}', selected: false, availableField: true  },
    { label: 'Student Work Phone', value: 'student{phoneWork}', selected: false, availableField: true  },
    { label: 'Student Work Ext', value: 'student{phoneWorkExt}', selected: false, availableField: true  },
    { label: 'Student Zip Code', value: 'student{postal}', selected: false, availableField: true  },
    { label: 'Student Pronouns', value: 'student{pronouns}', selected: false, availableField: true  },
    { label: 'Student State', value: 'student{state}', selected: false, availableField: true  },
    { label: 'Student Status', value: 'student{status}', selected: false, availableField: true  }
]

const COURSE_EXPORT_FIELDS = [
	// course
    { label: 'Course ID', value: 'id', selected: false, availableField: true },
    { label: 'Age Level', value: 'ageLevel', selected: false, availableField: true },
    { label: 'Course Day', value: 'dayOfWeek', selected: false, availableField: true },
    { label: 'Course Start Date', value: 'startDate', selected: false, availableField: true },
    { label: 'Course End Date', value: 'endDate', selected: false, availableField: true },
    { label: 'Is Tuition Based', value: 'isOpenArtsOrTuition', selected: false, availableField: true },
    { label: 'Medium', value: 'medium', selected: false, availableField: true },
    { label: 'Course Name', value: 'name', selected: false, availableField: true },
    { label: 'Number of Seats', value: 'numSeats', selected: false, availableField: true },
    { label: 'Number of Seats Available', value: 'numAvailable', selected: false, availableField: true },
    { label: 'Number of Students Dropped', value: 'numDropped', selected: false, availableField: true },
    { label: 'Number of Students Registered', value: 'numRegistered', selected: false, availableField: true },
    { label: 'Number of Students Transferred', value: 'numTransferred', selected: false, availableField: true },
    { label: 'Number of Students Waitlisted', value: 'numWaitlisted', selected: false, availableField: true },
    { label: 'Skill Level', value: 'skillLevel', selected: false, availableField: true },
    { label: 'Studio', value: 'studio', selected: false, availableField: true },
    { label: 'Suggested Contribution', value: 'suggestedContribution', selected: false, availableField: true },
    { label: 'Tuition Amount', value: 'tuitionAmount', selected: false, availableField: true },
    { label: 'Term', value: 'term{name}', selected: false, availableField: true },
    { label: 'Teaching Artist First Name', value: 'instructor{firstName}', selected: false, availableField: true },
    { label: 'Teaching Artist Last Name', value: 'instructor{lastName}', selected: false, availableField: true },
    { label: 'Teaching Artist Email', value: 'instructor{email}', selected: false, availableField: true },
    { label: 'Teaching Assistant First Name', value: 'assistant{firstName}', selected: false, availableField: true },
    { label: 'Teaching Assistant Last Name', value: 'assistant{lastName}', selected: false, availableField: true },
    { label: 'Teaching Assistant Email', value: 'assistant{email}', selected: false, availableField: true },

	// related registrations
    { label: 'Registration ID', value: 'registrations{items{id}}', selected: true, availableField: true },
    { label: 'Registration Amount', value: 'registrations{items{amount}}', selected: false, availableField: true },
	{ label: 'Registration Bill to Address', value: 'registrations{items{billToAddress}}', selected: false, availableField: true },
    { label: 'Registration Created At', value: 'registrations{items{createdAt}}', selected: false, availableField: true },
    { label: 'Registration Status', value: 'registrations{items{status}}', selected: false, availableField: true },
    { label: 'Registration Payment Status', value: 'registrations{items{paymentStatus}}', selected: false, availableField: true },
    { label: 'Registration Updated At', value: 'registrations{items{updatedAt}}', selected: false, availableField: true },
	
	
	// related student
    { label: 'Student ID', value: 'registrations{items{student{id}}}', selected: false, availableField: true },
    { label: 'Student Street Address', value: 'registrations{items{student{address1}}}', selected: false, availableField: true  },
    { label: 'Student Address 2', value: 'registrations{items{student{address2}}}', selected: false, availableField: true },
    { label: 'Student City', value: 'registrations{items{student{city}}}', selected: false, availableField: true  },
    { label: 'Student Country', value: 'registrations{items{student{country}}}', selected: false, availableField: true  },
    { label: 'Student Date of Birth', value: 'registrations{items{student{dob}}}', selected: false, availableField: true  },
    { label: 'Student Education', value: 'registrations{items{student{education}}}', selected: false, availableField: true  },
    { label: 'Student Email', value: 'registrations{items{student{email}}}', selected: false, availableField: true  },
    { label: 'Student Ethnicity', value: 'registrations{items{student{ethnicity}}}', selected: false, availableField: true  },
    { label: 'Student First Name', value: 'registrations{items{student{firstName}}}', selected: false, availableField: true  },
    { label: 'Student Gender', value: 'registrations{items{student{gender}}}', selected: false, availableField: true  },
    { label: 'Student Income', value: 'registrations{items{student{income}}}', selected: false, availableField: true  },
    { label: 'Student Last Activity', value: 'registrations{items{student{lastActivity}}}', selected: false, availableField: true  },
    { label: 'Student Last Login', value: 'registrations{items{student{lastLogin}}}', selected: false, availableField: true  },
    { label: 'Student Last Name', value: 'registrations{items{student{lastName}}}', selected: false, availableField: true  },
    { label: 'Student Middle Name', value: 'registrations{items{student{middleName}}}', selected: false, availableField: true  },
    { label: 'Student Notes', value: 'registrations{items{student{notes}}}', selected: false, availableField: true  },
    { label: 'Student Home Phone', value: 'registrations{items{student{phoneHome}}}', selected: false, availableField: true  },
    { label: 'Student Mobile Phone', value: 'registrations{items{student{phoneMobile}}}', selected: false, availableField: true  },
    { label: 'Student Work Phone', value: 'registrations{items{student{phoneWork}}}', selected: false, availableField: true  },
    { label: 'Student Work Ext', value: 'registrations{items{student{phoneWorkExt}}}', selected: false, availableField: true  },
    { label: 'Student Zip Code', value: 'registrations{items{student{postal}}}', selected: false, availableField: true  },
    { label: 'Student Pronouns', value: 'registrations{items{student{pronouns}}}', selected: false, availableField: true  },
    { label: 'Student State', value: 'registrations{items{student{state}}}', selected: false, availableField: true  },
    { label: 'Student Status', value: 'registrations{items{student{status}}}', selected: false, availableField: true  }
]

exports.STUDENT_EXPORT_FIELDS = STUDENT_EXPORT_FIELDS
exports.REGISTRTATION_EXPORT_FIELDS = REGISTRTATION_EXPORT_FIELDS
exports.COURSE_EXPORT_FIELDS = COURSE_EXPORT_FIELDS