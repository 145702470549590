export const getCourse = /* GraphQL */ `
  query GetCourse($id: ID!) {
    getCourse(id: $id) {
      id
      instructorId
      instructor {
        id
        firstName
		lastName
        createdAt
        updatedAt
      }
      termId
      term {
        id
        name
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
      createdBy
      updatedBy
      registrationSlug
      firstClassDate
      classRepeatCount
      classRepeatInterval
      classRepeatType
      classDayTimes
      isOpenArtsOrTuition
      tuitionAmount
      openArtsConfig
      image
      imageDescription
      registrationOpenTime
      description
      supplyList
      confirmationLinks
      confirmationNotes
      dayOfWeek
      endDate
      medium
      name
      numAvailable
      numRegistered
      numWaitlisted
      skillLevel
      startDate
      status
      studio
      timeOfDay
      allCourses
      location
      ageLevel
      numSeats
      showInSearch
    }
  }
`;


export const classesByCourseIdAndDate = /* GraphQL */ `
  query ClassesByCourseIdAndDate(
    $courseId: ID!
    $date: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelClassFilterInput
    $limit: Int
    $nextToken: String
  ) {
    classesByCourseIdAndDate(
      courseId: $courseId
      date: $date
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        courseId
        date
        startTime
        endTime
		attendance {
			items {
				status
			}
		}
      }
      nextToken
    }
  }
`;

export const instructorCoursesSortName = /* GraphQL */ `
  query InstructorCoursesSortName(
    $instructorId: ID!
    $name: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelCourseFilterInput
    $limit: Int
    $nextToken: String
  ) {
    instructorCoursesSortName(
      instructorId: $instructorId
      name: $name
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
		id
        instructorId
        termId
        dayOfWeek
        endDate
        name
        numRegistered
        startDate
        status
        studio
        timeOfDay
        location
        ageLevel
        numSeats
		classDayTimes
		term {
			id
			name
		}
      }
      nextToken
    }
  }
`;

export const instructorCoursesSortStatus = /* GraphQL */ `
  query InstructorCoursesSortStatus(
    $instructorId: ID!
    $status: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelCourseFilterInput
    $limit: Int
    $nextToken: String
  ) {
    instructorCoursesSortStatus(
      instructorId: $instructorId
      status: $status
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
		id
        instructorId
        termId
        dayOfWeek
        endDate
        name
        numRegistered
        startDate
        status
        studio
        timeOfDay
        location
        ageLevel
        numSeats
		classDayTimes
		term {
			id
			name
		}
      }
      nextToken
    }
  }
`;

export const instructorCoursesSortStartDate = /* GraphQL */ `
  query InstructorCoursesSortStartDate(
    $instructorId: ID!
    $startDate: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelCourseFilterInput
    $limit: Int
    $nextToken: String
  ) {
    instructorCoursesSortStartDate(
      instructorId: $instructorId
      startDate: $startDate
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
		id
        instructorId
        termId
        dayOfWeek
        endDate
        name
        numRegistered
        startDate
        status
        studio
        timeOfDay
        location
        ageLevel
        numSeats
		classDayTimes
		term {
			id
			name
		}
      }
      nextToken
    }
  }
`;

export const instructorCoursesSortEndDate = /* GraphQL */ `
  query InstructorCoursesSortEndDate(
    $instructorId: ID!
    $endDate: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelCourseFilterInput
    $limit: Int
    $nextToken: String
  ) {
    instructorCoursesSortEndDate(
      instructorId: $instructorId
      endDate: $endDate
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        instructorId
        termId
        dayOfWeek
        endDate
        name
        numRegistered
        skillLevel
        startDate
        status
        studio
        timeOfDay
        allCourses
        location
        ageLevel
        numSeats
		term {
			id
			name
		}
      }
      nextToken
    }
  }
`;

export const instructorCoursesSortRegCount = /* GraphQL */ `
  query InstructorCoursesSortRegCount(
    $instructorId: ID!
    $numRegistered: ModelIntKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelCourseFilterInput
    $limit: Int
    $nextToken: String
  ) {
    instructorCoursesSortRegCount(
      instructorId: $instructorId
      numRegistered: $numRegistered
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
		id
        instructorId
        termId
        dayOfWeek
        endDate
        name
        numRegistered
        startDate
        status
        studio
        timeOfDay
        location
        ageLevel
        numSeats
		classDayTimes
		term {
			id
			name
		}
      }
      nextToken
    }
  }
`;

export const activeTerms = /* GraphQL */ `
  query ActiveTerms(
    $isActive: Int!
    $name: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelTermFilterInput
    $limit: Int
    $nextToken: String
  ) {
    activeTerms(
      isActive: $isActive
      name: $name
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        name
      }
      nextToken
    }
  }
`;

export const registrationsByCourseIdAndLastName = /* GraphQL */ `
  query RegistrationsByCourseIdAndLastName(
    $courseId: ID!
    $lastName: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelRegistrationFilterInput
    $limit: Int
    $nextToken: String
  ) {
    registrationsByCourseIdAndLastName(
      courseId: $courseId
      lastName: $lastName
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
	  items {
        id
        courseId
        studentId
		studentName
        student{
          firstName
          lastName
          email
          dob
          pronouns
          phoneMobile
          address1
        }
        createdAt
        lastName
		status
        sub
        updatedAt
      }
      nextToken
    }
  }
`;

export const attendancesByClassId = /* GraphQL */ `
  query AttendancesByClassId(
    $classId: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelAttendanceFilterInput
    $limit: Int
    $nextToken: String
  ) {
    attendancesByClassId(
      classId: $classId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        classId
        studentId
        student{
          firstName
          lastName
        }
        createdAt
        status
        updatedAt
		registrationStatus
      }
      nextToken
    }
  }
`;

export const registrationsByCourseIdAndCreatedAt = /* GraphQL */ `
  query RegistrationsByCourseIdAndCreatedAt(
    $courseId: ID!
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelRegistrationFilterInput
    $limit: Int
    $nextToken: String
  ) {
    registrationsByCourseIdAndCreatedAt(
      courseId: $courseId
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        courseId
        studentId
        student{
		      id
          firstName
          lastName
          email
          dob
          pronouns
          phoneMobile
          address1
        }
        createdAt
        lastName
		    status
        sub
        updatedAt
      }
      nextToken
    }
  }
`;