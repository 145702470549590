import { defineStore } from 'pinia'
import { useStorage } from '@vueuse/core'

export const userStore = defineStore('user', {
	// a function that returns a fresh state
	state: () => ({
		user: useStorage('user', {
			id:'',
			email: '',
			firstName:'',
			middleName:'',
			lastName:'',
			gender:'',
			pronouns:'',
			ethnicity:'',
			education:'',
			income:'',
			dob:'',
			phoneHome:'',
			phoneWork:'',
			phoneWorkExt:'',
			phoneMobile:'',
			address1:'',
			address2:'',
			city:'',
			state:'',
			postal:'',
			country:'',
			emergencyContactFirstName: '',
			emergencyContactLastName: '',
			emergencyContactPhone: '',
			emergencyContactEmail: '',
			emergencyContactRelationship: '',
			sub:''
		}),

	}),
	
	// optional getters
	getters: {

	},

	// optional actions
	actions: {
		// setUser(payload){
		// 	state.user = payload
		// },
		// setLoginRedirect(state, payload){
		// 	state.loginRedirect = payload
		// },
		// setRegistrationId(state, payload){
		// 	state.registrationId = payload
		// }

	}
})