export const getCourse = /* GraphQL */ `
  query GetCourse($id: ID!) {
    getCourse(id: $id) {
      id
      instructorId
      instructor {
        id
        firstName
		lastName
        createdAt
        updatedAt
      }
      termId
      term {
        id
        name
        createdAt
        updatedAt
      }
      createdAt
      dayOfWeek
      endDate
      medium
      name
      numAvailable
      numRegistered
      numWaitlisted
      skillLevel
      startDate
      status
      studio
      timeOfDay
      allCourses
      updatedAt
    }
  }
`;

//Name
export const allCoursesSortName = /* GraphQL */ `
  query AllCoursesSortName(
    $allCourses: Int!
    $name: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelCourseFilterInput
    $limit: Int
    $nextToken: String
  ) {
    allCoursesSortName(
      allCourses: $allCourses
      name: $name
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        termId
        term {
          id
          name
          createdAt
          updatedAt
        }
        dayOfWeek
        endDate
        medium
        name
        numAvailable
        numRegistered
        numWaitlisted
        skillLevel
        startDate
        status
        studio
        timeOfDay
        allCourses
        startTime
        endTime
        isOpenArtsOrTuition
      }
      nextToken
    }
  }
`;


//Status
export const allCoursesSortStatus = /* GraphQL */ `
  query AllCoursesSortStatus(
    $allCourses: Int!
    $status: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelCourseFilterInput
    $limit: Int
    $nextToken: String
  ) {
    allCoursesSortStatus(
      allCourses: $allCourses
      status: $status
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        termId
        term {
          id
          name
          createdAt
          updatedAt
        }
        dayOfWeek
        endDate
        medium
        name
        numAvailable
        numRegistered
        numWaitlisted
        skillLevel
        startDate
        status
        studio
        timeOfDay
        allCourses
        startTime
        endTime
        isOpenArtsOrTuition
      }
      nextToken
    }
  }
`;

//Start Date
export const allCoursesSortStartDate = /* GraphQL */ `
  query AllCoursesSortStartDate(
    $allCourses: Int!
    $startDate: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelCourseFilterInput
    $limit: Int
    $nextToken: String
  ) {
    allCoursesSortStartDate(
      allCourses: $allCourses
      startDate: $startDate
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        termId
        term {
          id
          name
          createdAt
          updatedAt
        }
        dayOfWeek
        endDate
        medium
        name
        numAvailable
        numRegistered
        numWaitlisted
        skillLevel
        startDate
        status
        studio
        timeOfDay
        allCourses
        startTime
        endTime
        isOpenArtsOrTuition
      }
      nextToken
    }
  }
`;

//End Date
export const allCoursesSortEndDate = /* GraphQL */ `
  query AllCoursesSortEndDate(
    $allCourses: Int!
    $endDate: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelCourseFilterInput
    $limit: Int
    $nextToken: String
  ) {
    allCoursesSortEndDate(
      allCourses: $allCourses
      endDate: $endDate
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        termId
        term {
          id
          name
          createdAt
          updatedAt
        }
        dayOfWeek
        endDate
        medium
        name
        numAvailable
        numRegistered
        numWaitlisted
        skillLevel
        startDate
        status
        studio
        timeOfDay
        startTime
        endTime
        isOpenArtsOrTuition
        allCourses
      }
      nextToken
    }
  }
`;

//Registration Count
export const allCoursesSortRegCount = /* GraphQL */ `
  query AllCoursesSortRegCount(
    $allCourses: Int!
    $numRegistered: ModelIntKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelCourseFilterInput
    $limit: Int
    $nextToken: String
  ) {
    allCoursesSortRegCount(
      allCourses: $allCourses
      numRegistered: $numRegistered
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        termId
        term {
          id
          name
          createdAt
          updatedAt
        }
        dayOfWeek
        endDate
        medium
        name
        numAvailable
        numRegistered
        numWaitlisted
        skillLevel
        startDate
        status
        studio
        timeOfDay
        allCourses
        startTime
        endTime
        isOpenArtsOrTuition
      }
      nextToken
    }
  }
`;

//WaitList Count
export const allCoursesSortWaitListCount = /* GraphQL */ `
  query AllCoursesSortWaitListCount(
    $allCourses: Int!
    $numWaitlisted: ModelIntKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelCourseFilterInput
    $limit: Int
    $nextToken: String
  ) {
    allCoursesSortWaitListCount(
      allCourses: $allCourses
      numWaitlisted: $numWaitlisted
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        termId
        term {
          id
          name
          createdAt
          updatedAt
        }
        dayOfWeek
        endDate
        medium
        name
        numAvailable
        numRegistered
        numWaitlisted
        skillLevel
        startDate
        status
        studio
        timeOfDay
        allCourses
        startTime
        endTime
        isOpenArtsOrTuition
      }
      nextToken
    }
  }
`;

//Available Count
export const allCoursesSortAvailableCount = /* GraphQL */ `
  query AllCoursesSortAvailableCount(
    $allCourses: Int!
    $numAvailable: ModelIntKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelCourseFilterInput
    $limit: Int
    $nextToken: String
  ) {
    allCoursesSortAvailableCount(
      allCourses: $allCourses
      numAvailable: $numAvailable
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        termId
        term {
          id
          name
          createdAt
          updatedAt
        }
        dayOfWeek
        endDate
        medium
        name
        numAvailable
        numRegistered
        numWaitlisted
        skillLevel
        startDate
        status
        studio
        timeOfDay
        allCourses
        startTime
        endTime
        isOpenArtsOrTuition
      }
      nextToken
    }
  }
`;

export const allCoursesSortIsOpenArts = /* GraphQL */ `
  query AllCoursesSortIsOpenArts(
    $allCourses: Int!
    $isOpenArtsOrTuition: ModelIntKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelCourseFilterInput
    $limit: Int
    $nextToken: String
  ) {
    allCoursesSortIsOpenArts(
      allCourses: $allCourses
      isOpenArtsOrTuition: $isOpenArtsOrTuition
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        termId
        term {
          id
          name
          createdAt
          updatedAt
        }
        dayOfWeek
        endDate
        medium
        name
        numAvailable
        numRegistered
        numWaitlisted
        skillLevel
        startDate
        status
        studio
        timeOfDay
        allCourses
        startTime
        endTime
        isOpenArtsOrTuition
      }
      nextToken
    }
  }
`;

export const searchCourses = /* GraphQL */ `
  query SearchCourses(
    $filter: SearchableCourseFilterInput
    $sort: [SearchableCourseSortInput]
    $limit: Int
    $nextToken: String
    $from: Int
    $aggregates: [SearchableCourseAggregationInput]
  ) {
    searchCourses(
      filter: $filter
      sort: $sort
      limit: $limit
      nextToken: $nextToken
      from: $from
      aggregates: $aggregates
    ) {
		items {
        id
        termId
        term {
          id
          name
          createdAt
          updatedAt
        }
        dayOfWeek
        endDate
        medium
        name
        numAvailable
        numRegistered
        numWaitlisted
        skillLevel
        startDate
        status
        studio
        timeOfDay
        allCourses
        startTime
        endTime
        isOpenArtsOrTuition
      }
      nextToken
      total
      aggregateItems {
        name
        result {
          ... on SearchableAggregateScalarResult {
            value
          }
          ... on SearchableAggregateBucketResult {
            buckets {
              key
              doc_count
            }
          }
        }
      }
    }
  }
`;

export const listTerms = /* GraphQL */ `
  query ListTerms(
    $id: ID
    $filter: ModelTermFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listTerms(
      id: $id
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        id
        name
      }
      nextToken
    }
  }
`;