export const createCourse = /* GraphQL */ `
  mutation CreateCourse(
    $input: CreateCourseInput!
    $condition: ModelCourseConditionInput
  ) {
    createCourse(input: $input, condition: $condition) {
	  id
      instructorId
      instructor {
		id
        firstName
        lastName
        pronouns
      }
      termId
      term {
		id
		name
        startDate
      }
      assistantId
      assistant {
		id
        firstName
        lastName
        pronouns
      }
      classes {
		items {
			id
			date
			day
			startTime
			endTime
		}
        nextToken
      }
	  registrations {
		items {
			id
			status
		}
	  }
      rootCourseId
      rootCourse {
        name
      }
      createdAt
      updatedAt
      createdBy
      updatedBy
      registrationSlug
      firstClassDate
      classRepeatCount
      classRepeatInterval
      classRepeatType
      classDayTimes
      isOpenArtsOrTuition
      tuitionAmount
      openArtsConfig
      image
      imageDescription
      registrationOpenTime
      description
      supplyList
      confirmationLinks
      confirmationNotes
      dayOfWeek
      endDate
      medium
      name
      numAvailable
      numRegistered
      numWaitlisted
      numDropped
      numTransferred
      skillLevel
      startDate
      status
      studio
      timeOfDay
      suggestedContribution
      location
      ageLevel
      numSeats
      showInSearch
      donationAmount
      minimumRegistrations
	  qbItem
	  qbClass
    }
  }
`;
export const updateCourse = /* GraphQL */ `
  mutation UpdateCourse(
    $input: UpdateCourseInput!
    $condition: ModelCourseConditionInput
  ) {
    updateCourse(input: $input, condition: $condition) {
      id
      instructorId
      instructor {
		    id
        firstName
        lastName
        pronouns
      }
      termId
      term {
        id
        name
        startDate
      }
      assistantId
      assistant {
		    id
        firstName
        lastName
        pronouns
      }
      classes {
        items {
          id
          date
          day
          startTime
          endTime
        }
        nextToken
      }
	    registrations {
        items {
          id
          status
        }
	    }
      rootCourseId
      rootCourse {
        name
      }
      createdAt
      updatedAt
      createdBy
      updatedBy
      registrationSlug
      firstClassDate
      classRepeatCount
      classRepeatInterval
      classRepeatType
      classDayTimes
      isOpenArtsOrTuition
      tuitionAmount
      openArtsConfig
      image
      imageDescription
      registrationOpenTime
      description
      supplyList
      confirmationLinks
      confirmationNotes
      dayOfWeek
      endDate
      medium
      name
      numAvailable
      numRegistered
      numWaitlisted
      numDropped
      numTransferred
      skillLevel
      startDate
      status
      studio
      timeOfDay
      suggestedContribution
      location
      ageLevel
      numSeats
      showInSearch
      donationAmount
      minimumRegistrations
      qbItem
      qbClass
    }
  }
`;

export const deleteCourse = /* GraphQL */ `
  mutation DeleteCourse(
    $input: DeleteCourseInput!
    $condition: ModelCourseConditionInput
  ) {
    deleteCourse(input: $input, condition: $condition) {
      id
    }
  }
`;

export const batchCreateClasses = /* GraphQL */ `
  mutation BatchCreateClasses($classes: [CreateClassInput]) {
    batchCreateClasses(classes: $classes) {
      id
      courseId
      date
      day
      startTime
      endTime
      createdAt
      updatedAt
    }
  }
`;

export const deleteClass = /* GraphQL */ `
  mutation DeleteClass(
    $input: DeleteClassInput!
    $condition: ModelClassConditionInput
  ) {
    deleteClass(input: $input, condition: $condition) {
      id
    }
  }
`;

export const createRegistration = /* GraphQL */ `
  mutation CreateRegistration(
    $input: CreateRegistrationInput!
    $condition: ModelRegistrationConditionInput
  ) {
    createRegistration(input: $input, condition: $condition) {
      id
      courseId
      studentId
      createdAt
      createdBy
      lastName
      sub
      updatedBy
      status
      updatedAt
    }
  }
`;

export const createEmail = /* GraphQL */ `
  mutation CreateEmail(
    $input: CreateEmailInput!
    $condition: ModelEmailConditionInput
  ) {
    createEmail(input: $input, condition: $condition) {
      id
    }
  }
`;

export const createAttendance = /* GraphQL */ `
  mutation CreateAttendance(
    $input: CreateAttendanceInput!
    $condition: ModelAttendanceConditionInput
  ) {
    createAttendance(input: $input, condition: $condition) {
      id
    }
  }
`;

export const updateAttendance = /* GraphQL */ `
  mutation UpdateAttendance(
    $input: UpdateAttendanceInput!
    $condition: ModelAttendanceConditionInput
  ) {
    updateAttendance(input: $input, condition: $condition) {
      id
    }
  }
`;