import { defineStore } from 'pinia'
import { useStorage } from '@vueuse/core'

export const adminFiltersStore = defineStore('adminFilters', {
	// a function that returns a fresh state
	state: () => ({
		appliedFilters: useStorage('adminFilters', {
			courseList: {
				searchText:'',
				sortField: 'startDate',
				sortDirection: 'DESC',
				queryName: null,
				query: null,
				filters: [[],[],[],[],[],[]]
			},
			studentList: {
				searchText:'',
				sortField: 'firstName',
				sortDirection: 'ASC',
				queryName: null,
				query: null,
				filters: [[]]
			}
		})
	}),
	
	// optional getters
	getters: {

	},

	// optional actions
	actions: {

	}
})