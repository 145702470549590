
import AccountConfirmation from './AccountConfirmation.vue'
import AuthLayout from '@/layouts/AuthLayout.vue'

const config = {
	mainRoute: {
		name: "AccountConfirmation",
		path: "/account-confirmation/:email?",
		component: AccountConfirmation,
		layout: AuthLayout
	}
}

export { config }