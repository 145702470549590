export const registrationsBySubSortCreatedAt = /* GraphQL */ `
  query RegistrationsBySubSortCreatedAt(
    $sub: String!
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelRegistrationFilterInput
    $limit: Int
    $nextToken: String
  ) {
    registrationsBySubSortCreatedAt(
      sub: $sub
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        courseId
        course {
          id
          name
          ageLevel
          status
          skillLevel
          medium
          termId
		  image
		  imageDescription
          term {
            id
            name
          }
          instructorId
          instructor {
            firstName
            lastName
            pronouns
          }
          assistantId
          assistant {
            firstName
            lastName
            pronouns
          }
          classes {
			items {
				courseId
				date
				day
				startTime
				endTime
			}
          }
        }
        studentId
        createdAt
        sub
        status
		statusUpdatedAt
        updatedAt
      }
      nextToken
    }
  }
`;