export const createRegistration = /* GraphQL */ `
  mutation CreateRegistration(
    $input: CreateRegistrationInput!
    $condition: ModelRegistrationConditionInput
  ) {
    createRegistration(input: $input, condition: $condition) {
      id
      courseId
      studentId
      createdAt
      createdBy
      lastName
      sub
      updatedBy
      status
      updatedAt
    }
  }
`;
