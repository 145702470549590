export const createCourse = /* GraphQL */ `
  mutation CreateCourse(
    $input: CreateCourseInput!
    $condition: ModelCourseConditionInput
  ) {
    createCourse(input: $input, condition: $condition) {
      id
      instructorId
      instructor {
        id
        firstName
		lastName
        createdAt
        updatedAt
      }
      termId
      term {
        id
        name
        createdAt
        isActive
        updatedAt
      }
      assistantId
      assistant {
        id
        firstName
		lastName
        createdAt
        updatedAt
      }
      classes {
        nextToken
      }
      createdAt
      updatedAt
      createdBy
      updatedBy
      registrationSlug
      firstClassDate
      classRepeatCount
      classRepeatInterval
      classRepeatType
      classDayTimes
      isOpenArtsOrTuition
      tuitionAmount
      openArtsConfig
      image
      imageDescription
      registrationOpenTime
      description
      supplyList
      confirmationLinks
      confirmationNotes
      dayOfWeek
      endDate
      medium
      name
      numAvailable
      numRegistered
      numWaitlisted
      numDropped
      numTransferred
      skillLevel
      startDate
      status
      studio
      timeOfDay
      allCourses
      location
      ageLevel
      numSeats
      showInSearch
      minimumRegistrations
    }
  }
`;
export const updateCourse = /* GraphQL */ `
  mutation UpdateCourse(
    $input: UpdateCourseInput!
    $condition: ModelCourseConditionInput
  ) {
    updateCourse(input: $input, condition: $condition) {
      id
      instructorId
      instructor {
        id
        firstName
		lastName
        createdAt
        updatedAt
      }
      termId
      term {
        id
        name
        createdAt
        isActive
        updatedAt
      }
      assistantId
      assistant {
        id
        firstName
		lastName
        createdAt
        updatedAt
      }
      classes {
        nextToken
      }
      createdAt
      updatedAt
      createdBy
      updatedBy
      registrationSlug
      firstClassDate
      classRepeatCount
      classRepeatInterval
      classRepeatType
      classDayTimes
      isOpenArtsOrTuition
      tuitionAmount
      openArtsConfig
      image
      imageDescription
      registrationOpenTime
      description
      supplyList
      confirmationLinks
      confirmationNotes
      dayOfWeek
      endDate
      medium
      name
      numAvailable
      numRegistered
      numWaitlisted
      numDropped
      numTransferred
      skillLevel
      startDate
      status
      studio
      timeOfDay
      allCourses
      location
      ageLevel
      numSeats
      showInSearch
      minimumRegistrations
    }
  }
`;
export const updateRegistration = /* GraphQL */ `
  mutation UpdateRegistration(
    $input: UpdateRegistrationInput!
    $condition: ModelRegistrationConditionInput
  ) {
    updateRegistration(input: $input, condition: $condition) {
      id
      courseId
      course {
        id
        assistantId
        instructorSub
        instructorId
        termId
        rootCourseId
        ageLevel
        classDayTimes
        classRepeatCount
        classRepeatInterval
        classRepeatType
        confirmationLinks
        confirmationNotes
        createdAt
        createdBy
        dayOfWeek
        description
        donationAmount
        endDate
        endTime
        firstClassDate
        image
        imageDescription
        isOpenArtsOrTuition
        location
        medium
        minimumRegistrations
        name
        numAvailable
        numDropped
        numRegistered
        numSeats
        numTransferred
        numWaitlisted
        openArtsConfig
        proClassId
        qbClass
        qbItem
        registrationOpenTime
        registrationSlug
        showInSearch
        skillLevel
        startDate
        startTime
        status
        studio
        suggestedContribution
        supplyList
        timeOfDay
        tuitionAmount
        updatedAt
        updatedBy
        allCourses
      }
      studentId
      student {
        id
        address1
        address2
        city
        country
        createdBy
        dob
        education
        email
        emergencyContactFirstName
        emergencyContactLastName
        emergencyContactPhone
        emergencyContactEmail
        emergencyContactRelationship
        ethnicity
        firstName
        gender
        income
        isInstructor
        isMainAccount
        lastActivity
        lastLogin
        lastName
        middleName
        notes
        optInEmails
        phoneHome
        phoneMobile
        phoneWork
        phoneWorkExt
        postal
        proClassId
        pronouns
        relationshipToAccount
        state
        status
        sub
        updatedBy
        allStudents
        createdAt
        updatedAt
      }
      amount
      createdAt
      createdBy
      billToAddress
      lastName
      status
      paymentStatus
      statusUpdatedAt
      sub
      quickbooksToken
      updatedBy
      allRegistrations
      updatedAt
    }
  }
`;
