/* tslint:disable */
/* eslint-disable */
// this is an auto generated file. This will be overwritten

import * as APITypes from "../API";
type GeneratedMutation<InputType, OutputType> = string & {
  __generatedMutationInput: InputType;
  __generatedMutationOutput: OutputType;
};

export const batchCreateClasses = /* GraphQL */ `mutation BatchCreateClasses($classes: [CreateClassInput]) {
  batchCreateClasses(classes: $classes) {
    id
    courseId
    course {
      id
      assistantId
      instructorSub
      instructorId
      termId
      rootCourseId
      ageLevel
      classDayTimes
      classRepeatCount
      classRepeatInterval
      classRepeatType
      confirmationLinks
      confirmationNotes
      createdAt
      createdBy
      dayOfWeek
      description
      donationAmount
      endDate
      endTime
      firstClassDate
      image
      imageDescription
      isOpenArtsOrTuition
      location
      medium
      minimumRegistrations
      name
      numAvailable
      numDropped
      numRegistered
      numSeats
      numTransferred
      numWaitlisted
      openArtsConfig
      proClassId
      qbClass
      qbItem
      registrationOpenTime
      registrationSlug
      showInSearch
      skillLevel
      startDate
      startTime
      status
      studio
      suggestedContribution
      supplyList
      timeOfDay
      tuitionAmount
      updatedAt
      updatedBy
      allCourses
      __typename
    }
    attendance {
      nextToken
      __typename
    }
    createdAt
    date
    day
    endTime
    startTime
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.BatchCreateClassesMutationVariables,
  APITypes.BatchCreateClassesMutation
>;
export const batchUpdateAttendance = /* GraphQL */ `mutation BatchUpdateAttendance($attendance: [UpdateAttendanceInput]) {
  batchUpdateAttendance(attendance: $attendance) {
    id
    classId
    studentId
    student {
      id
      address1
      address2
      city
      country
      createdBy
      dob
      education
      email
      emergencyContactFirstName
      emergencyContactLastName
      emergencyContactPhone
      emergencyContactEmail
      emergencyContactRelationship
      ethnicity
      firstName
      gender
      income
      isInstructor
      isMainAccount
      lastActivity
      lastLogin
      lastName
      middleName
      notes
      optInEmails
      phoneHome
      phoneMobile
      phoneWork
      phoneWorkExt
      postal
      proClassId
      pronouns
      relationshipToAccount
      state
      status
      sub
      updatedBy
      allStudents
      createdAt
      updatedAt
      __typename
    }
    createdAt
    status
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.BatchUpdateAttendanceMutationVariables,
  APITypes.BatchUpdateAttendanceMutation
>;
export const createAttendance = /* GraphQL */ `mutation CreateAttendance(
  $input: CreateAttendanceInput!
  $condition: ModelAttendanceConditionInput
) {
  createAttendance(input: $input, condition: $condition) {
    id
    classId
    studentId
    student {
      id
      address1
      address2
      city
      country
      createdBy
      dob
      education
      email
      emergencyContactFirstName
      emergencyContactLastName
      emergencyContactPhone
      emergencyContactEmail
      emergencyContactRelationship
      ethnicity
      firstName
      gender
      income
      isInstructor
      isMainAccount
      lastActivity
      lastLogin
      lastName
      middleName
      notes
      optInEmails
      phoneHome
      phoneMobile
      phoneWork
      phoneWorkExt
      postal
      proClassId
      pronouns
      relationshipToAccount
      state
      status
      sub
      updatedBy
      allStudents
      createdAt
      updatedAt
      __typename
    }
    createdAt
    status
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateAttendanceMutationVariables,
  APITypes.CreateAttendanceMutation
>;
export const updateAttendance = /* GraphQL */ `mutation UpdateAttendance(
  $input: UpdateAttendanceInput!
  $condition: ModelAttendanceConditionInput
) {
  updateAttendance(input: $input, condition: $condition) {
    id
    classId
    studentId
    student {
      id
      address1
      address2
      city
      country
      createdBy
      dob
      education
      email
      emergencyContactFirstName
      emergencyContactLastName
      emergencyContactPhone
      emergencyContactEmail
      emergencyContactRelationship
      ethnicity
      firstName
      gender
      income
      isInstructor
      isMainAccount
      lastActivity
      lastLogin
      lastName
      middleName
      notes
      optInEmails
      phoneHome
      phoneMobile
      phoneWork
      phoneWorkExt
      postal
      proClassId
      pronouns
      relationshipToAccount
      state
      status
      sub
      updatedBy
      allStudents
      createdAt
      updatedAt
      __typename
    }
    createdAt
    status
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateAttendanceMutationVariables,
  APITypes.UpdateAttendanceMutation
>;
export const deleteAttendance = /* GraphQL */ `mutation DeleteAttendance(
  $input: DeleteAttendanceInput!
  $condition: ModelAttendanceConditionInput
) {
  deleteAttendance(input: $input, condition: $condition) {
    id
    classId
    studentId
    student {
      id
      address1
      address2
      city
      country
      createdBy
      dob
      education
      email
      emergencyContactFirstName
      emergencyContactLastName
      emergencyContactPhone
      emergencyContactEmail
      emergencyContactRelationship
      ethnicity
      firstName
      gender
      income
      isInstructor
      isMainAccount
      lastActivity
      lastLogin
      lastName
      middleName
      notes
      optInEmails
      phoneHome
      phoneMobile
      phoneWork
      phoneWorkExt
      postal
      proClassId
      pronouns
      relationshipToAccount
      state
      status
      sub
      updatedBy
      allStudents
      createdAt
      updatedAt
      __typename
    }
    createdAt
    status
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteAttendanceMutationVariables,
  APITypes.DeleteAttendanceMutation
>;
export const createClass = /* GraphQL */ `mutation CreateClass(
  $input: CreateClassInput!
  $condition: ModelClassConditionInput
) {
  createClass(input: $input, condition: $condition) {
    id
    courseId
    course {
      id
      assistantId
      instructorSub
      instructorId
      termId
      rootCourseId
      ageLevel
      classDayTimes
      classRepeatCount
      classRepeatInterval
      classRepeatType
      confirmationLinks
      confirmationNotes
      createdAt
      createdBy
      dayOfWeek
      description
      donationAmount
      endDate
      endTime
      firstClassDate
      image
      imageDescription
      isOpenArtsOrTuition
      location
      medium
      minimumRegistrations
      name
      numAvailable
      numDropped
      numRegistered
      numSeats
      numTransferred
      numWaitlisted
      openArtsConfig
      proClassId
      qbClass
      qbItem
      registrationOpenTime
      registrationSlug
      showInSearch
      skillLevel
      startDate
      startTime
      status
      studio
      suggestedContribution
      supplyList
      timeOfDay
      tuitionAmount
      updatedAt
      updatedBy
      allCourses
      __typename
    }
    attendance {
      nextToken
      __typename
    }
    createdAt
    date
    day
    endTime
    startTime
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateClassMutationVariables,
  APITypes.CreateClassMutation
>;
export const updateClass = /* GraphQL */ `mutation UpdateClass(
  $input: UpdateClassInput!
  $condition: ModelClassConditionInput
) {
  updateClass(input: $input, condition: $condition) {
    id
    courseId
    course {
      id
      assistantId
      instructorSub
      instructorId
      termId
      rootCourseId
      ageLevel
      classDayTimes
      classRepeatCount
      classRepeatInterval
      classRepeatType
      confirmationLinks
      confirmationNotes
      createdAt
      createdBy
      dayOfWeek
      description
      donationAmount
      endDate
      endTime
      firstClassDate
      image
      imageDescription
      isOpenArtsOrTuition
      location
      medium
      minimumRegistrations
      name
      numAvailable
      numDropped
      numRegistered
      numSeats
      numTransferred
      numWaitlisted
      openArtsConfig
      proClassId
      qbClass
      qbItem
      registrationOpenTime
      registrationSlug
      showInSearch
      skillLevel
      startDate
      startTime
      status
      studio
      suggestedContribution
      supplyList
      timeOfDay
      tuitionAmount
      updatedAt
      updatedBy
      allCourses
      __typename
    }
    attendance {
      nextToken
      __typename
    }
    createdAt
    date
    day
    endTime
    startTime
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateClassMutationVariables,
  APITypes.UpdateClassMutation
>;
export const deleteClass = /* GraphQL */ `mutation DeleteClass(
  $input: DeleteClassInput!
  $condition: ModelClassConditionInput
) {
  deleteClass(input: $input, condition: $condition) {
    id
    courseId
    course {
      id
      assistantId
      instructorSub
      instructorId
      termId
      rootCourseId
      ageLevel
      classDayTimes
      classRepeatCount
      classRepeatInterval
      classRepeatType
      confirmationLinks
      confirmationNotes
      createdAt
      createdBy
      dayOfWeek
      description
      donationAmount
      endDate
      endTime
      firstClassDate
      image
      imageDescription
      isOpenArtsOrTuition
      location
      medium
      minimumRegistrations
      name
      numAvailable
      numDropped
      numRegistered
      numSeats
      numTransferred
      numWaitlisted
      openArtsConfig
      proClassId
      qbClass
      qbItem
      registrationOpenTime
      registrationSlug
      showInSearch
      skillLevel
      startDate
      startTime
      status
      studio
      suggestedContribution
      supplyList
      timeOfDay
      tuitionAmount
      updatedAt
      updatedBy
      allCourses
      __typename
    }
    attendance {
      nextToken
      __typename
    }
    createdAt
    date
    day
    endTime
    startTime
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteClassMutationVariables,
  APITypes.DeleteClassMutation
>;
export const createCourse = /* GraphQL */ `mutation CreateCourse(
  $input: CreateCourseInput!
  $condition: ModelCourseConditionInput
) {
  createCourse(input: $input, condition: $condition) {
    id
    assistantId
    assistant {
      id
      address1
      address2
      city
      country
      createdBy
      dob
      education
      email
      emergencyContactFirstName
      emergencyContactLastName
      emergencyContactPhone
      emergencyContactEmail
      emergencyContactRelationship
      ethnicity
      firstName
      gender
      income
      isInstructor
      isMainAccount
      lastActivity
      lastLogin
      lastName
      middleName
      notes
      optInEmails
      phoneHome
      phoneMobile
      phoneWork
      phoneWorkExt
      postal
      proClassId
      pronouns
      relationshipToAccount
      state
      status
      sub
      updatedBy
      allStudents
      createdAt
      updatedAt
      __typename
    }
    classes {
      nextToken
      __typename
    }
    instructorSub
    instructorId
    instructor {
      id
      address1
      address2
      city
      country
      createdBy
      dob
      education
      email
      emergencyContactFirstName
      emergencyContactLastName
      emergencyContactPhone
      emergencyContactEmail
      emergencyContactRelationship
      ethnicity
      firstName
      gender
      income
      isInstructor
      isMainAccount
      lastActivity
      lastLogin
      lastName
      middleName
      notes
      optInEmails
      phoneHome
      phoneMobile
      phoneWork
      phoneWorkExt
      postal
      proClassId
      pronouns
      relationshipToAccount
      state
      status
      sub
      updatedBy
      allStudents
      createdAt
      updatedAt
      __typename
    }
    termId
    term {
      id
      createdAt
      endDate
      name
      startDate
      isActive
      updatedAt
      __typename
    }
    registrations {
      nextToken
      __typename
    }
    rootCourseId
    rootCourse {
      id
      latestTermId
      latestTermName
      ageLevel
      createdBy
      description
      image
      imageDescription
      isOpenArtsOrTuition
      location
      medium
      name
      openArtsConfig
      skillLevel
      studio
      supplyList
      tuitionAmount
      updatedBy
      allRootCourses
      createdAt
      updatedAt
      __typename
    }
    ageLevel
    classDayTimes
    classRepeatCount
    classRepeatInterval
    classRepeatType
    confirmationLinks
    confirmationNotes
    createdAt
    createdBy
    dayOfWeek
    description
    donationAmount
    endDate
    endTime
    firstClassDate
    image
    imageDescription
    isOpenArtsOrTuition
    location
    medium
    minimumRegistrations
    name
    numAvailable
    numDropped
    numRegistered
    numSeats
    numTransferred
    numWaitlisted
    openArtsConfig
    proClassId
    qbClass
    qbItem
    registrationOpenTime
    registrationSlug
    showInSearch
    skillLevel
    startDate
    startTime
    status
    studio
    suggestedContribution
    supplyList
    timeOfDay
    tuitionAmount
    updatedAt
    updatedBy
    allCourses
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateCourseMutationVariables,
  APITypes.CreateCourseMutation
>;
export const updateCourse = /* GraphQL */ `mutation UpdateCourse(
  $input: UpdateCourseInput!
  $condition: ModelCourseConditionInput
) {
  updateCourse(input: $input, condition: $condition) {
    id
    assistantId
    assistant {
      id
      address1
      address2
      city
      country
      createdBy
      dob
      education
      email
      emergencyContactFirstName
      emergencyContactLastName
      emergencyContactPhone
      emergencyContactEmail
      emergencyContactRelationship
      ethnicity
      firstName
      gender
      income
      isInstructor
      isMainAccount
      lastActivity
      lastLogin
      lastName
      middleName
      notes
      optInEmails
      phoneHome
      phoneMobile
      phoneWork
      phoneWorkExt
      postal
      proClassId
      pronouns
      relationshipToAccount
      state
      status
      sub
      updatedBy
      allStudents
      createdAt
      updatedAt
      __typename
    }
    classes {
      nextToken
      __typename
    }
    instructorSub
    instructorId
    instructor {
      id
      address1
      address2
      city
      country
      createdBy
      dob
      education
      email
      emergencyContactFirstName
      emergencyContactLastName
      emergencyContactPhone
      emergencyContactEmail
      emergencyContactRelationship
      ethnicity
      firstName
      gender
      income
      isInstructor
      isMainAccount
      lastActivity
      lastLogin
      lastName
      middleName
      notes
      optInEmails
      phoneHome
      phoneMobile
      phoneWork
      phoneWorkExt
      postal
      proClassId
      pronouns
      relationshipToAccount
      state
      status
      sub
      updatedBy
      allStudents
      createdAt
      updatedAt
      __typename
    }
    termId
    term {
      id
      createdAt
      endDate
      name
      startDate
      isActive
      updatedAt
      __typename
    }
    registrations {
      nextToken
      __typename
    }
    rootCourseId
    rootCourse {
      id
      latestTermId
      latestTermName
      ageLevel
      createdBy
      description
      image
      imageDescription
      isOpenArtsOrTuition
      location
      medium
      name
      openArtsConfig
      skillLevel
      studio
      supplyList
      tuitionAmount
      updatedBy
      allRootCourses
      createdAt
      updatedAt
      __typename
    }
    ageLevel
    classDayTimes
    classRepeatCount
    classRepeatInterval
    classRepeatType
    confirmationLinks
    confirmationNotes
    createdAt
    createdBy
    dayOfWeek
    description
    donationAmount
    endDate
    endTime
    firstClassDate
    image
    imageDescription
    isOpenArtsOrTuition
    location
    medium
    minimumRegistrations
    name
    numAvailable
    numDropped
    numRegistered
    numSeats
    numTransferred
    numWaitlisted
    openArtsConfig
    proClassId
    qbClass
    qbItem
    registrationOpenTime
    registrationSlug
    showInSearch
    skillLevel
    startDate
    startTime
    status
    studio
    suggestedContribution
    supplyList
    timeOfDay
    tuitionAmount
    updatedAt
    updatedBy
    allCourses
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateCourseMutationVariables,
  APITypes.UpdateCourseMutation
>;
export const deleteCourse = /* GraphQL */ `mutation DeleteCourse(
  $input: DeleteCourseInput!
  $condition: ModelCourseConditionInput
) {
  deleteCourse(input: $input, condition: $condition) {
    id
    assistantId
    assistant {
      id
      address1
      address2
      city
      country
      createdBy
      dob
      education
      email
      emergencyContactFirstName
      emergencyContactLastName
      emergencyContactPhone
      emergencyContactEmail
      emergencyContactRelationship
      ethnicity
      firstName
      gender
      income
      isInstructor
      isMainAccount
      lastActivity
      lastLogin
      lastName
      middleName
      notes
      optInEmails
      phoneHome
      phoneMobile
      phoneWork
      phoneWorkExt
      postal
      proClassId
      pronouns
      relationshipToAccount
      state
      status
      sub
      updatedBy
      allStudents
      createdAt
      updatedAt
      __typename
    }
    classes {
      nextToken
      __typename
    }
    instructorSub
    instructorId
    instructor {
      id
      address1
      address2
      city
      country
      createdBy
      dob
      education
      email
      emergencyContactFirstName
      emergencyContactLastName
      emergencyContactPhone
      emergencyContactEmail
      emergencyContactRelationship
      ethnicity
      firstName
      gender
      income
      isInstructor
      isMainAccount
      lastActivity
      lastLogin
      lastName
      middleName
      notes
      optInEmails
      phoneHome
      phoneMobile
      phoneWork
      phoneWorkExt
      postal
      proClassId
      pronouns
      relationshipToAccount
      state
      status
      sub
      updatedBy
      allStudents
      createdAt
      updatedAt
      __typename
    }
    termId
    term {
      id
      createdAt
      endDate
      name
      startDate
      isActive
      updatedAt
      __typename
    }
    registrations {
      nextToken
      __typename
    }
    rootCourseId
    rootCourse {
      id
      latestTermId
      latestTermName
      ageLevel
      createdBy
      description
      image
      imageDescription
      isOpenArtsOrTuition
      location
      medium
      name
      openArtsConfig
      skillLevel
      studio
      supplyList
      tuitionAmount
      updatedBy
      allRootCourses
      createdAt
      updatedAt
      __typename
    }
    ageLevel
    classDayTimes
    classRepeatCount
    classRepeatInterval
    classRepeatType
    confirmationLinks
    confirmationNotes
    createdAt
    createdBy
    dayOfWeek
    description
    donationAmount
    endDate
    endTime
    firstClassDate
    image
    imageDescription
    isOpenArtsOrTuition
    location
    medium
    minimumRegistrations
    name
    numAvailable
    numDropped
    numRegistered
    numSeats
    numTransferred
    numWaitlisted
    openArtsConfig
    proClassId
    qbClass
    qbItem
    registrationOpenTime
    registrationSlug
    showInSearch
    skillLevel
    startDate
    startTime
    status
    studio
    suggestedContribution
    supplyList
    timeOfDay
    tuitionAmount
    updatedAt
    updatedBy
    allCourses
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteCourseMutationVariables,
  APITypes.DeleteCourseMutation
>;
export const createEmail = /* GraphQL */ `mutation CreateEmail(
  $input: CreateEmailInput!
  $condition: ModelEmailConditionInput
) {
  createEmail(input: $input, condition: $condition) {
    id
    studentId
    student {
      id
      address1
      address2
      city
      country
      createdBy
      dob
      education
      email
      emergencyContactFirstName
      emergencyContactLastName
      emergencyContactPhone
      emergencyContactEmail
      emergencyContactRelationship
      ethnicity
      firstName
      gender
      income
      isInstructor
      isMainAccount
      lastActivity
      lastLogin
      lastName
      middleName
      notes
      optInEmails
      phoneHome
      phoneMobile
      phoneWork
      phoneWorkExt
      postal
      proClassId
      pronouns
      relationshipToAccount
      state
      status
      sub
      updatedBy
      allStudents
      createdAt
      updatedAt
      __typename
    }
    body
    createdAt
    sentAt
    status
    subject
    to
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateEmailMutationVariables,
  APITypes.CreateEmailMutation
>;
export const updateEmail = /* GraphQL */ `mutation UpdateEmail(
  $input: UpdateEmailInput!
  $condition: ModelEmailConditionInput
) {
  updateEmail(input: $input, condition: $condition) {
    id
    studentId
    student {
      id
      address1
      address2
      city
      country
      createdBy
      dob
      education
      email
      emergencyContactFirstName
      emergencyContactLastName
      emergencyContactPhone
      emergencyContactEmail
      emergencyContactRelationship
      ethnicity
      firstName
      gender
      income
      isInstructor
      isMainAccount
      lastActivity
      lastLogin
      lastName
      middleName
      notes
      optInEmails
      phoneHome
      phoneMobile
      phoneWork
      phoneWorkExt
      postal
      proClassId
      pronouns
      relationshipToAccount
      state
      status
      sub
      updatedBy
      allStudents
      createdAt
      updatedAt
      __typename
    }
    body
    createdAt
    sentAt
    status
    subject
    to
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateEmailMutationVariables,
  APITypes.UpdateEmailMutation
>;
export const deleteEmail = /* GraphQL */ `mutation DeleteEmail(
  $input: DeleteEmailInput!
  $condition: ModelEmailConditionInput
) {
  deleteEmail(input: $input, condition: $condition) {
    id
    studentId
    student {
      id
      address1
      address2
      city
      country
      createdBy
      dob
      education
      email
      emergencyContactFirstName
      emergencyContactLastName
      emergencyContactPhone
      emergencyContactEmail
      emergencyContactRelationship
      ethnicity
      firstName
      gender
      income
      isInstructor
      isMainAccount
      lastActivity
      lastLogin
      lastName
      middleName
      notes
      optInEmails
      phoneHome
      phoneMobile
      phoneWork
      phoneWorkExt
      postal
      proClassId
      pronouns
      relationshipToAccount
      state
      status
      sub
      updatedBy
      allStudents
      createdAt
      updatedAt
      __typename
    }
    body
    createdAt
    sentAt
    status
    subject
    to
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteEmailMutationVariables,
  APITypes.DeleteEmailMutation
>;
export const createExport = /* GraphQL */ `mutation CreateExport(
  $input: CreateExportInput!
  $condition: ModelExportConditionInput
) {
  createExport(input: $input, condition: $condition) {
    id
    type
    createdAt
    exportTable
    fieldMapping
    file
    name
    query
    recordIdList
    status
    user
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateExportMutationVariables,
  APITypes.CreateExportMutation
>;
export const updateExport = /* GraphQL */ `mutation UpdateExport(
  $input: UpdateExportInput!
  $condition: ModelExportConditionInput
) {
  updateExport(input: $input, condition: $condition) {
    id
    type
    createdAt
    exportTable
    fieldMapping
    file
    name
    query
    recordIdList
    status
    user
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateExportMutationVariables,
  APITypes.UpdateExportMutation
>;
export const deleteExport = /* GraphQL */ `mutation DeleteExport(
  $input: DeleteExportInput!
  $condition: ModelExportConditionInput
) {
  deleteExport(input: $input, condition: $condition) {
    id
    type
    createdAt
    exportTable
    fieldMapping
    file
    name
    query
    recordIdList
    status
    user
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteExportMutationVariables,
  APITypes.DeleteExportMutation
>;
export const createRegistration = /* GraphQL */ `mutation CreateRegistration(
  $input: CreateRegistrationInput!
  $condition: ModelRegistrationConditionInput
) {
  createRegistration(input: $input, condition: $condition) {
    id
    courseId
    course {
      id
      assistantId
      instructorSub
      instructorId
      termId
      rootCourseId
      ageLevel
      classDayTimes
      classRepeatCount
      classRepeatInterval
      classRepeatType
      confirmationLinks
      confirmationNotes
      createdAt
      createdBy
      dayOfWeek
      description
      donationAmount
      endDate
      endTime
      firstClassDate
      image
      imageDescription
      isOpenArtsOrTuition
      location
      medium
      minimumRegistrations
      name
      numAvailable
      numDropped
      numRegistered
      numSeats
      numTransferred
      numWaitlisted
      openArtsConfig
      proClassId
      qbClass
      qbItem
      registrationOpenTime
      registrationSlug
      showInSearch
      skillLevel
      startDate
      startTime
      status
      studio
      suggestedContribution
      supplyList
      timeOfDay
      tuitionAmount
      updatedAt
      updatedBy
      allCourses
      __typename
    }
    studentId
    student {
      id
      address1
      address2
      city
      country
      createdBy
      dob
      education
      email
      emergencyContactFirstName
      emergencyContactLastName
      emergencyContactPhone
      emergencyContactEmail
      emergencyContactRelationship
      ethnicity
      firstName
      gender
      income
      isInstructor
      isMainAccount
      lastActivity
      lastLogin
      lastName
      middleName
      notes
      optInEmails
      phoneHome
      phoneMobile
      phoneWork
      phoneWorkExt
      postal
      proClassId
      pronouns
      relationshipToAccount
      state
      status
      sub
      updatedBy
      allStudents
      createdAt
      updatedAt
      __typename
    }
    amount
    createdAt
    createdBy
    billToAddress
    lastName
    studentName
    status
    paymentStatus
    statusUpdatedAt
    sub
    quickbooksToken
    updatedBy
    allRegistrations
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateRegistrationMutationVariables,
  APITypes.CreateRegistrationMutation
>;
export const updateRegistration = /* GraphQL */ `mutation UpdateRegistration(
  $input: UpdateRegistrationInput!
  $condition: ModelRegistrationConditionInput
) {
  updateRegistration(input: $input, condition: $condition) {
    id
    courseId
    course {
      id
      assistantId
      instructorSub
      instructorId
      termId
      rootCourseId
      ageLevel
      classDayTimes
      classRepeatCount
      classRepeatInterval
      classRepeatType
      confirmationLinks
      confirmationNotes
      createdAt
      createdBy
      dayOfWeek
      description
      donationAmount
      endDate
      endTime
      firstClassDate
      image
      imageDescription
      isOpenArtsOrTuition
      location
      medium
      minimumRegistrations
      name
      numAvailable
      numDropped
      numRegistered
      numSeats
      numTransferred
      numWaitlisted
      openArtsConfig
      proClassId
      qbClass
      qbItem
      registrationOpenTime
      registrationSlug
      showInSearch
      skillLevel
      startDate
      startTime
      status
      studio
      suggestedContribution
      supplyList
      timeOfDay
      tuitionAmount
      updatedAt
      updatedBy
      allCourses
      __typename
    }
    studentId
    student {
      id
      address1
      address2
      city
      country
      createdBy
      dob
      education
      email
      emergencyContactFirstName
      emergencyContactLastName
      emergencyContactPhone
      emergencyContactEmail
      emergencyContactRelationship
      ethnicity
      firstName
      gender
      income
      isInstructor
      isMainAccount
      lastActivity
      lastLogin
      lastName
      middleName
      notes
      optInEmails
      phoneHome
      phoneMobile
      phoneWork
      phoneWorkExt
      postal
      proClassId
      pronouns
      relationshipToAccount
      state
      status
      sub
      updatedBy
      allStudents
      createdAt
      updatedAt
      __typename
    }
    amount
    createdAt
    createdBy
    billToAddress
    lastName
    studentName
    status
    paymentStatus
    statusUpdatedAt
    sub
    quickbooksToken
    updatedBy
    allRegistrations
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateRegistrationMutationVariables,
  APITypes.UpdateRegistrationMutation
>;
export const deleteRegistration = /* GraphQL */ `mutation DeleteRegistration(
  $input: DeleteRegistrationInput!
  $condition: ModelRegistrationConditionInput
) {
  deleteRegistration(input: $input, condition: $condition) {
    id
    courseId
    course {
      id
      assistantId
      instructorSub
      instructorId
      termId
      rootCourseId
      ageLevel
      classDayTimes
      classRepeatCount
      classRepeatInterval
      classRepeatType
      confirmationLinks
      confirmationNotes
      createdAt
      createdBy
      dayOfWeek
      description
      donationAmount
      endDate
      endTime
      firstClassDate
      image
      imageDescription
      isOpenArtsOrTuition
      location
      medium
      minimumRegistrations
      name
      numAvailable
      numDropped
      numRegistered
      numSeats
      numTransferred
      numWaitlisted
      openArtsConfig
      proClassId
      qbClass
      qbItem
      registrationOpenTime
      registrationSlug
      showInSearch
      skillLevel
      startDate
      startTime
      status
      studio
      suggestedContribution
      supplyList
      timeOfDay
      tuitionAmount
      updatedAt
      updatedBy
      allCourses
      __typename
    }
    studentId
    student {
      id
      address1
      address2
      city
      country
      createdBy
      dob
      education
      email
      emergencyContactFirstName
      emergencyContactLastName
      emergencyContactPhone
      emergencyContactEmail
      emergencyContactRelationship
      ethnicity
      firstName
      gender
      income
      isInstructor
      isMainAccount
      lastActivity
      lastLogin
      lastName
      middleName
      notes
      optInEmails
      phoneHome
      phoneMobile
      phoneWork
      phoneWorkExt
      postal
      proClassId
      pronouns
      relationshipToAccount
      state
      status
      sub
      updatedBy
      allStudents
      createdAt
      updatedAt
      __typename
    }
    amount
    createdAt
    createdBy
    billToAddress
    lastName
    studentName
    status
    paymentStatus
    statusUpdatedAt
    sub
    quickbooksToken
    updatedBy
    allRegistrations
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteRegistrationMutationVariables,
  APITypes.DeleteRegistrationMutation
>;
export const createRegistrationReservation = /* GraphQL */ `mutation CreateRegistrationReservation(
  $input: CreateRegistrationReservationInput!
  $condition: ModelRegistrationReservationConditionInput
) {
  createRegistrationReservation(input: $input, condition: $condition) {
    id
    courseId
    studentId
    messageDuplicationId
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateRegistrationReservationMutationVariables,
  APITypes.CreateRegistrationReservationMutation
>;
export const updateRegistrationReservation = /* GraphQL */ `mutation UpdateRegistrationReservation(
  $input: UpdateRegistrationReservationInput!
  $condition: ModelRegistrationReservationConditionInput
) {
  updateRegistrationReservation(input: $input, condition: $condition) {
    id
    courseId
    studentId
    messageDuplicationId
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateRegistrationReservationMutationVariables,
  APITypes.UpdateRegistrationReservationMutation
>;
export const deleteRegistrationReservation = /* GraphQL */ `mutation DeleteRegistrationReservation(
  $input: DeleteRegistrationReservationInput!
  $condition: ModelRegistrationReservationConditionInput
) {
  deleteRegistrationReservation(input: $input, condition: $condition) {
    id
    courseId
    studentId
    messageDuplicationId
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteRegistrationReservationMutationVariables,
  APITypes.DeleteRegistrationReservationMutation
>;
export const createRootCourse = /* GraphQL */ `mutation CreateRootCourse(
  $input: CreateRootCourseInput!
  $condition: ModelRootCourseConditionInput
) {
  createRootCourse(input: $input, condition: $condition) {
    id
    courses {
      nextToken
      __typename
    }
    latestTermId
    latestTerm {
      id
      createdAt
      endDate
      name
      startDate
      isActive
      updatedAt
      __typename
    }
    latestTermName
    ageLevel
    createdBy
    description
    image
    imageDescription
    isOpenArtsOrTuition
    location
    medium
    name
    openArtsConfig
    skillLevel
    studio
    supplyList
    tuitionAmount
    updatedBy
    allRootCourses
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateRootCourseMutationVariables,
  APITypes.CreateRootCourseMutation
>;
export const updateRootCourse = /* GraphQL */ `mutation UpdateRootCourse(
  $input: UpdateRootCourseInput!
  $condition: ModelRootCourseConditionInput
) {
  updateRootCourse(input: $input, condition: $condition) {
    id
    courses {
      nextToken
      __typename
    }
    latestTermId
    latestTerm {
      id
      createdAt
      endDate
      name
      startDate
      isActive
      updatedAt
      __typename
    }
    latestTermName
    ageLevel
    createdBy
    description
    image
    imageDescription
    isOpenArtsOrTuition
    location
    medium
    name
    openArtsConfig
    skillLevel
    studio
    supplyList
    tuitionAmount
    updatedBy
    allRootCourses
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateRootCourseMutationVariables,
  APITypes.UpdateRootCourseMutation
>;
export const deleteRootCourse = /* GraphQL */ `mutation DeleteRootCourse(
  $input: DeleteRootCourseInput!
  $condition: ModelRootCourseConditionInput
) {
  deleteRootCourse(input: $input, condition: $condition) {
    id
    courses {
      nextToken
      __typename
    }
    latestTermId
    latestTerm {
      id
      createdAt
      endDate
      name
      startDate
      isActive
      updatedAt
      __typename
    }
    latestTermName
    ageLevel
    createdBy
    description
    image
    imageDescription
    isOpenArtsOrTuition
    location
    medium
    name
    openArtsConfig
    skillLevel
    studio
    supplyList
    tuitionAmount
    updatedBy
    allRootCourses
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteRootCourseMutationVariables,
  APITypes.DeleteRootCourseMutation
>;
export const createRelatedStudent = /* GraphQL */ `mutation CreateRelatedStudent(
  $input: CreateRelatedStudentInput!
  $condition: ModelRelatedStudentConditionInput
) {
  createRelatedStudent(input: $input, condition: $condition) {
    childId
    parentId
    parent {
      id
      address1
      address2
      city
      country
      createdBy
      dob
      education
      email
      emergencyContactFirstName
      emergencyContactLastName
      emergencyContactPhone
      emergencyContactEmail
      emergencyContactRelationship
      ethnicity
      firstName
      gender
      income
      isInstructor
      isMainAccount
      lastActivity
      lastLogin
      lastName
      middleName
      notes
      optInEmails
      phoneHome
      phoneMobile
      phoneWork
      phoneWorkExt
      postal
      proClassId
      pronouns
      relationshipToAccount
      state
      status
      sub
      updatedBy
      allStudents
      createdAt
      updatedAt
      __typename
    }
    child {
      id
      address1
      address2
      city
      country
      createdBy
      dob
      education
      email
      emergencyContactFirstName
      emergencyContactLastName
      emergencyContactPhone
      emergencyContactEmail
      emergencyContactRelationship
      ethnicity
      firstName
      gender
      income
      isInstructor
      isMainAccount
      lastActivity
      lastLogin
      lastName
      middleName
      notes
      optInEmails
      phoneHome
      phoneMobile
      phoneWork
      phoneWorkExt
      postal
      proClassId
      pronouns
      relationshipToAccount
      state
      status
      sub
      updatedBy
      allStudents
      createdAt
      updatedAt
      __typename
    }
    sub
    id
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateRelatedStudentMutationVariables,
  APITypes.CreateRelatedStudentMutation
>;
export const updateRelatedStudent = /* GraphQL */ `mutation UpdateRelatedStudent(
  $input: UpdateRelatedStudentInput!
  $condition: ModelRelatedStudentConditionInput
) {
  updateRelatedStudent(input: $input, condition: $condition) {
    childId
    parentId
    parent {
      id
      address1
      address2
      city
      country
      createdBy
      dob
      education
      email
      emergencyContactFirstName
      emergencyContactLastName
      emergencyContactPhone
      emergencyContactEmail
      emergencyContactRelationship
      ethnicity
      firstName
      gender
      income
      isInstructor
      isMainAccount
      lastActivity
      lastLogin
      lastName
      middleName
      notes
      optInEmails
      phoneHome
      phoneMobile
      phoneWork
      phoneWorkExt
      postal
      proClassId
      pronouns
      relationshipToAccount
      state
      status
      sub
      updatedBy
      allStudents
      createdAt
      updatedAt
      __typename
    }
    child {
      id
      address1
      address2
      city
      country
      createdBy
      dob
      education
      email
      emergencyContactFirstName
      emergencyContactLastName
      emergencyContactPhone
      emergencyContactEmail
      emergencyContactRelationship
      ethnicity
      firstName
      gender
      income
      isInstructor
      isMainAccount
      lastActivity
      lastLogin
      lastName
      middleName
      notes
      optInEmails
      phoneHome
      phoneMobile
      phoneWork
      phoneWorkExt
      postal
      proClassId
      pronouns
      relationshipToAccount
      state
      status
      sub
      updatedBy
      allStudents
      createdAt
      updatedAt
      __typename
    }
    sub
    id
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateRelatedStudentMutationVariables,
  APITypes.UpdateRelatedStudentMutation
>;
export const deleteRelatedStudent = /* GraphQL */ `mutation DeleteRelatedStudent(
  $input: DeleteRelatedStudentInput!
  $condition: ModelRelatedStudentConditionInput
) {
  deleteRelatedStudent(input: $input, condition: $condition) {
    childId
    parentId
    parent {
      id
      address1
      address2
      city
      country
      createdBy
      dob
      education
      email
      emergencyContactFirstName
      emergencyContactLastName
      emergencyContactPhone
      emergencyContactEmail
      emergencyContactRelationship
      ethnicity
      firstName
      gender
      income
      isInstructor
      isMainAccount
      lastActivity
      lastLogin
      lastName
      middleName
      notes
      optInEmails
      phoneHome
      phoneMobile
      phoneWork
      phoneWorkExt
      postal
      proClassId
      pronouns
      relationshipToAccount
      state
      status
      sub
      updatedBy
      allStudents
      createdAt
      updatedAt
      __typename
    }
    child {
      id
      address1
      address2
      city
      country
      createdBy
      dob
      education
      email
      emergencyContactFirstName
      emergencyContactLastName
      emergencyContactPhone
      emergencyContactEmail
      emergencyContactRelationship
      ethnicity
      firstName
      gender
      income
      isInstructor
      isMainAccount
      lastActivity
      lastLogin
      lastName
      middleName
      notes
      optInEmails
      phoneHome
      phoneMobile
      phoneWork
      phoneWorkExt
      postal
      proClassId
      pronouns
      relationshipToAccount
      state
      status
      sub
      updatedBy
      allStudents
      createdAt
      updatedAt
      __typename
    }
    sub
    id
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteRelatedStudentMutationVariables,
  APITypes.DeleteRelatedStudentMutation
>;
export const createStudent = /* GraphQL */ `mutation CreateStudent(
  $input: CreateStudentInput!
  $condition: ModelStudentConditionInput
) {
  createStudent(input: $input, condition: $condition) {
    id
    registrations {
      nextToken
      __typename
    }
    children {
      nextToken
      __typename
    }
    parents {
      nextToken
      __typename
    }
    address1
    address2
    city
    country
    createdBy
    dob
    education
    email
    emergencyContactFirstName
    emergencyContactLastName
    emergencyContactPhone
    emergencyContactEmail
    emergencyContactRelationship
    ethnicity
    firstName
    gender
    income
    isInstructor
    isMainAccount
    lastActivity
    lastLogin
    lastName
    middleName
    notes
    optInEmails
    phoneHome
    phoneMobile
    phoneWork
    phoneWorkExt
    postal
    proClassId
    pronouns
    relationshipToAccount
    state
    status
    sub
    updatedBy
    allStudents
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateStudentMutationVariables,
  APITypes.CreateStudentMutation
>;
export const updateStudent = /* GraphQL */ `mutation UpdateStudent(
  $input: UpdateStudentInput!
  $condition: ModelStudentConditionInput
) {
  updateStudent(input: $input, condition: $condition) {
    id
    registrations {
      nextToken
      __typename
    }
    children {
      nextToken
      __typename
    }
    parents {
      nextToken
      __typename
    }
    address1
    address2
    city
    country
    createdBy
    dob
    education
    email
    emergencyContactFirstName
    emergencyContactLastName
    emergencyContactPhone
    emergencyContactEmail
    emergencyContactRelationship
    ethnicity
    firstName
    gender
    income
    isInstructor
    isMainAccount
    lastActivity
    lastLogin
    lastName
    middleName
    notes
    optInEmails
    phoneHome
    phoneMobile
    phoneWork
    phoneWorkExt
    postal
    proClassId
    pronouns
    relationshipToAccount
    state
    status
    sub
    updatedBy
    allStudents
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateStudentMutationVariables,
  APITypes.UpdateStudentMutation
>;
export const deleteStudent = /* GraphQL */ `mutation DeleteStudent(
  $input: DeleteStudentInput!
  $condition: ModelStudentConditionInput
) {
  deleteStudent(input: $input, condition: $condition) {
    id
    registrations {
      nextToken
      __typename
    }
    children {
      nextToken
      __typename
    }
    parents {
      nextToken
      __typename
    }
    address1
    address2
    city
    country
    createdBy
    dob
    education
    email
    emergencyContactFirstName
    emergencyContactLastName
    emergencyContactPhone
    emergencyContactEmail
    emergencyContactRelationship
    ethnicity
    firstName
    gender
    income
    isInstructor
    isMainAccount
    lastActivity
    lastLogin
    lastName
    middleName
    notes
    optInEmails
    phoneHome
    phoneMobile
    phoneWork
    phoneWorkExt
    postal
    proClassId
    pronouns
    relationshipToAccount
    state
    status
    sub
    updatedBy
    allStudents
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteStudentMutationVariables,
  APITypes.DeleteStudentMutation
>;
export const createTerm = /* GraphQL */ `mutation CreateTerm(
  $input: CreateTermInput!
  $condition: ModelTermConditionInput
) {
  createTerm(input: $input, condition: $condition) {
    id
    createdAt
    endDate
    name
    startDate
    isActive
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateTermMutationVariables,
  APITypes.CreateTermMutation
>;
export const updateTerm = /* GraphQL */ `mutation UpdateTerm(
  $input: UpdateTermInput!
  $condition: ModelTermConditionInput
) {
  updateTerm(input: $input, condition: $condition) {
    id
    createdAt
    endDate
    name
    startDate
    isActive
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateTermMutationVariables,
  APITypes.UpdateTermMutation
>;
export const deleteTerm = /* GraphQL */ `mutation DeleteTerm(
  $input: DeleteTermInput!
  $condition: ModelTermConditionInput
) {
  deleteTerm(input: $input, condition: $condition) {
    id
    createdAt
    endDate
    name
    startDate
    isActive
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteTermMutationVariables,
  APITypes.DeleteTermMutation
>;
export const createSystem = /* GraphQL */ `mutation CreateSystem(
  $input: CreateSystemInput!
  $condition: ModelSystemConditionInput
) {
  createSystem(input: $input, condition: $condition) {
    id
    confirmationCancellationPolicy
    confirmationIntroduction
    confirmationWaitlist
    courseSearchOpening
    courseSearchTitle
    qbOauthToken
    qbAccounts
    qbClasses
    qbDepositToAccount
    qbDonationItem
    qbDonationItemClass
    qbItems
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateSystemMutationVariables,
  APITypes.CreateSystemMutation
>;
export const updateSystem = /* GraphQL */ `mutation UpdateSystem(
  $input: UpdateSystemInput!
  $condition: ModelSystemConditionInput
) {
  updateSystem(input: $input, condition: $condition) {
    id
    confirmationCancellationPolicy
    confirmationIntroduction
    confirmationWaitlist
    courseSearchOpening
    courseSearchTitle
    qbOauthToken
    qbAccounts
    qbClasses
    qbDepositToAccount
    qbDonationItem
    qbDonationItemClass
    qbItems
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateSystemMutationVariables,
  APITypes.UpdateSystemMutation
>;
export const deleteSystem = /* GraphQL */ `mutation DeleteSystem(
  $input: DeleteSystemInput!
  $condition: ModelSystemConditionInput
) {
  deleteSystem(input: $input, condition: $condition) {
    id
    confirmationCancellationPolicy
    confirmationIntroduction
    confirmationWaitlist
    courseSearchOpening
    courseSearchTitle
    qbOauthToken
    qbAccounts
    qbClasses
    qbDepositToAccount
    qbDonationItem
    qbDonationItemClass
    qbItems
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteSystemMutationVariables,
  APITypes.DeleteSystemMutation
>;
export const createSelectList = /* GraphQL */ `mutation CreateSelectList(
  $input: CreateSelectListInput!
  $condition: ModelSelectListConditionInput
) {
  createSelectList(input: $input, condition: $condition) {
    id
    options {
      nextToken
      __typename
    }
    category
    key
    name
    sortIndex
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateSelectListMutationVariables,
  APITypes.CreateSelectListMutation
>;
export const updateSelectList = /* GraphQL */ `mutation UpdateSelectList(
  $input: UpdateSelectListInput!
  $condition: ModelSelectListConditionInput
) {
  updateSelectList(input: $input, condition: $condition) {
    id
    options {
      nextToken
      __typename
    }
    category
    key
    name
    sortIndex
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateSelectListMutationVariables,
  APITypes.UpdateSelectListMutation
>;
export const deleteSelectList = /* GraphQL */ `mutation DeleteSelectList(
  $input: DeleteSelectListInput!
  $condition: ModelSelectListConditionInput
) {
  deleteSelectList(input: $input, condition: $condition) {
    id
    options {
      nextToken
      __typename
    }
    category
    key
    name
    sortIndex
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteSelectListMutationVariables,
  APITypes.DeleteSelectListMutation
>;
export const createSelectListOption = /* GraphQL */ `mutation CreateSelectListOption(
  $input: CreateSelectListOptionInput!
  $condition: ModelSelectListOptionConditionInput
) {
  createSelectListOption(input: $input, condition: $condition) {
    id
    selectListId
    deleted
    label
    sortIndex
    value
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateSelectListOptionMutationVariables,
  APITypes.CreateSelectListOptionMutation
>;
export const updateSelectListOption = /* GraphQL */ `mutation UpdateSelectListOption(
  $input: UpdateSelectListOptionInput!
  $condition: ModelSelectListOptionConditionInput
) {
  updateSelectListOption(input: $input, condition: $condition) {
    id
    selectListId
    deleted
    label
    sortIndex
    value
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateSelectListOptionMutationVariables,
  APITypes.UpdateSelectListOptionMutation
>;
export const deleteSelectListOption = /* GraphQL */ `mutation DeleteSelectListOption(
  $input: DeleteSelectListOptionInput!
  $condition: ModelSelectListOptionConditionInput
) {
  deleteSelectListOption(input: $input, condition: $condition) {
    id
    selectListId
    deleted
    label
    sortIndex
    value
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteSelectListOptionMutationVariables,
  APITypes.DeleteSelectListOptionMutation
>;
