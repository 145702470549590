import { defineStore } from 'pinia'
import { useStorage } from '@vueuse/core'

export const systemStore = defineStore('system', {
	// a function that returns a fresh state
	state: () => ({
		system: useStorage('system', {
			id: "",
			confirmationIntroduction: "",
			confirmationCancellationPolicy: "",
			courseSearchOpening: "",
			courseSearchTitle: ""
		})
	}),
	
	// optional getters
	getters: {

	},

	// optional actions
	actions: {

	}
})