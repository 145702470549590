import { HomeIcon } from '@heroicons/vue//24/outline'
import Dashboard from './DashboardMain.vue'

const config = {
	mainRoute: {
		name: "Dashboard",
		path: "/dashboard",
		component: Dashboard
	},
	nav: {
		name: "Dashboard",
		to: "Dashboard",
		position: 0,
		show: true		
	},
	module: "dashboard",
	auth: ['admin']
}

export { config }