import InstructorCourses from "./InstructorCoursesMain.vue"
import InstructorCourseDetails from './components/InstructorCourseDetails.vue'

const config = {
	mainRoute: {
		name: "InstructorCourses",
		path: "/teaching-artist-courses",
		component: InstructorCourses,
		title: "Teaching Artist Dashboard"
	},
	instructorCourseDetail: {
		name: "InstructorCourseDetails",
		path: "/teaching-artist-courses/:id",
		component: InstructorCourseDetails,
		props: true
	},
	nav: {
		name: "TA Dashboard",
		to: "InstructorCourses",
		position: 0,
		show: true
	},
	module: "instructor",
	auth: ['instructor']
}

export { config }