const VL_LOCATION_OPTIONS = [
	{label: "In-person", value: "In-person" },
	{label: "Online", value: "Online"}
]

const VL_TIME_OF_DAY_OPTIONS = [
	{label: "Morning", value: "Morning" },
	{label: "Afternoon", value: "Afternoon"},
	{label: "Evening", value: "Evening"}
]

const VL_COUNTRY_OPTIONS = [ 
    { id: 229, label: "United States", value: "US"}, 
    { id: 0, label: "Afghanistan", value: "AF"}, 
    { id: 1, label: "Åland Islands", value: "AX"}, 
    { id: 2, label: "Albania", value: "AL"}, 
    { id: 3, label: "Algeria", value: "DZ"}, 
    { id: 4, label: "American Samoa", value: "AS"}, 
    { id: 5, label: "AndorrA", value: "AD"}, 
    { id: 6, label: "Angola", value: "AO"}, 
    { id: 7, label: "Anguilla", value: "AI"}, 
    { id: 8, label: "Antarctica", value: "AQ"}, 
    { id: 9, label: "Antigua and Barbuda", value: "AG"}, 
    { id: 10, label: "Argentina", value: "AR"}, 
    { id: 11, label: "Armenia", value: "AM"}, 
    { id: 12, label: "Aruba", value: "AW"}, 
    { id: 13, label: "Australia", value: "AU"}, 
    { id: 14, label: "Austria", value: "AT"}, 
    { id: 15, label: "Azerbaijan", value: "AZ"}, 
    { id: 16, label: "Bahamas", value: "BS"}, 
    { id: 17, label: "Bahrain", value: "BH"}, 
    { id: 18, label: "Bangladesh", value: "BD"}, 
    { id: 19, label: "Barbados", value: "BB"}, 
    { id: 20, label: "Belarus", value: "BY"}, 
    { id: 21, label: "Belgium", value: "BE"}, 
    { id: 22, label: "Belize", value: "BZ"}, 
    { id: 23, label: "Benin", value: "BJ"}, 
    { id: 24, label: "Bermuda", value: "BM"}, 
    { id: 25, label: "Bhutan", value: "BT"}, 
    { id: 26, label: "Bolivia", value: "BO"}, 
    { id: 27, label: "Bosnia and Herzegovina", value: "BA"}, 
    { id: 28, label: "Botswana", value: "BW"}, 
    { id: 29, label: "Bouvet Island", value: "BV"}, 
    { id: 30, label: "Brazil", value: "BR"}, 
    { id: 31, label: "British Indian Ocean Territory", value: "IO"}, 
    { id: 32, label: "Brunei Darussalam", value: "BN"}, 
    { id: 33, label: "Bulgaria", value: "BG"}, 
    { id: 34, label: "Burkina Faso", value: "BF"}, 
    { id: 35, label: "Burundi", value: "BI"}, 
    { id: 36, label: "Cambodia", value: "KH"}, 
    { id: 37, label: "Cameroon", value: "CM"}, 
    { id: 38, label: "Canada", value: "CA"}, 
    { id: 39, label: "Cape Verde", value: "CV"}, 
    { id: 40, label: "Cayman Islands", value: "KY"}, 
    { id: 41, label: "Central African Republic", value: "CF"}, 
    { id: 42, label: "Chad", value: "TD"}, 
    { id: 43, label: "Chile", value: "CL"}, 
    { id: 44, label: "China", value: "CN"}, 
    { id: 45, label: "Christmas Island", value: "CX"}, 
    { id: 46, label: "Cocos (Keeling) Islands", value: "CC"}, 
    { id: 47, label: "Colombia", value: "CO"}, 
    { id: 48, label: "Comoros", value: "KM"}, 
    { id: 49, label: "Congo", value: "CG"}, 
    { id: 50, label: "Congo, The Democratic Republic of the", value: "CD"}, 
    { id: 51, label: "Cook Islands", value: "CK"}, 
    { id: 52, label: "Costa Rica", value: "CR"}, 
    { id: 53, label: "Cote D\"Ivoire", value: "CI"}, 
    { id: 54, label: "Croatia", value: "HR"}, 
    { id: 55, label: "Cuba", value: "CU"}, 
    { id: 56, label: "Cyprus", value: "CY"}, 
    { id: 57, label: "Czech Republic", value: "CZ"}, 
    { id: 58, label: "Denmark", value: "DK"}, 
    { id: 59, label: "Djibouti", value: "DJ"}, 
    { id: 60, label: "Dominica", value: "DM"}, 
    { id: 61, label: "Dominican Republic", value: "DO"}, 
    { id: 62, label: "Ecuador", value: "EC"}, 
    { id: 63, label: "Egypt", value: "EG"}, 
    { id: 64, label: "El Salvador", value: "SV"}, 
    { id: 65, label: "Equatorial Guinea", value: "GQ"}, 
    { id: 66, label: "Eritrea", value: "ER"}, 
    { id: 67, label: "Estonia", value: "EE"}, 
    { id: 68, label: "Ethiopia", value: "ET"}, 
    { id: 69, label: "Falkland Islands (Malvinas)", value: "FK"}, 
    { id: 70, label: "Faroe Islands", value: "FO"}, 
    { id: 71, label: "Fiji", value: "FJ"}, 
    { id: 72, label: "Finland", value: "FI"}, 
    { id: 73, label: "France", value: "FR"}, 
    { id: 74, label: "French Guiana", value: "GF"}, 
    { id: 75, label: "French Polynesia", value: "PF"}, 
    { id: 76, label: "French Southern Territories", value: "TF"}, 
    { id: 77, label: "Gabon", value: "GA"}, 
    { id: 78, label: "Gambia", value: "GM"}, 
    { id: 79, label: "Georgia", value: "GE"}, 
    { id: 80, label: "Germany", value: "DE"}, 
    { id: 81, label: "Ghana", value: "GH"}, 
    { id: 82, label: "Gibraltar", value: "GI"}, 
    { id: 83, label: "Greece", value: "GR"}, 
    { id: 84, label: "Greenland", value: "GL"}, 
    { id: 85, label: "Grenada", value: "GD"}, 
    { id: 86, label: "Guadeloupe", value: "GP"}, 
    { id: 87, label: "Guam", value: "GU"}, 
    { id: 88, label: "Guatemala", value: "GT"}, 
    { id: 89, label: "Guernsey", value: "GG"}, 
    { id: 90, label: "Guinea", value: "GN"}, 
    { id: 91, label: "Guinea-Bissau", value: "GW"}, 
    { id: 92, label: "Guyana", value: "GY"}, 
    { id: 93, label: "Haiti", value: "HT"}, 
    { id: 94, label: "Heard Island and Mcdonald Islands", value: "HM"}, 
    { id: 95, label: "Holy See (Vatican City State)", value: "VA"}, 
    { id: 96, label: "Honduras", value: "HN"}, 
    { id: 97, label: "Hong Kong", value: "HK"}, 
    { id: 98, label: "Hungary", value: "HU"}, 
    { id: 99, label: "Iceland", value: "IS"}, 
    { id: 100, label: "India", value: "IN"}, 
    { id: 101, label: "Indonesia", value: "ID"}, 
    { id: 102, label: "Iran, Islamic Republic Of", value: "IR"}, 
    { id: 103, label: "Iraq", value: "IQ"}, 
    { id: 104, label: "Ireland", value: "IE"}, 
    { id: 105, label: "Isle of Man", value: "IM"}, 
    { id: 106, label: "Israel", value: "IL"}, 
    { id: 107, label: "Italy", value: "IT"}, 
    { id: 108, label: "Jamaica", value: "JM"}, 
    { id: 109, label: "Japan", value: "JP"}, 
    { id: 110, label: "Jersey", value: "JE"}, 
    { id: 111, label: "Jordan", value: "JO"}, 
    { id: 112, label: "Kazakhstan", value: "KZ"}, 
    { id: 113, label: "Kenya", value: "KE"}, 
    { id: 114, label: "Kiribati", value: "KI"}, 
    { id: 115, label: "Korea, Democratic People\"S Republic of", value: "KP"}, 
    { id: 116, label: "Korea, Republic of", value: "KR"}, 
    { id: 117, label: "Kuwait", value: "KW"}, 
    { id: 118, label: "Kyrgyzstan", value: "KG"}, 
    { id: 119, label: "Lao People\"S Democratic Republic", value: "LA"}, 
    { id: 120, label: "Latvia", value: "LV"}, 
    { id: 121, label: "Lebanon", value: "LB"}, 
    { id: 122, label: "Lesotho", value: "LS"}, 
    { id: 123, label: "Liberia", value: "LR"}, 
    { id: 124, label: "Libyan Arab Jamahiriya", value: "LY"}, 
    { id: 125, label: "Liechtenstein", value: "LI"}, 
    { id: 126, label: "Lithuania", value: "LT"}, 
    { id: 127, label: "Luxembourg", value: "LU"}, 
    { id: 128, label: "Macao", value: "MO"}, 
    { id: 129, label: "Macedonia, The Former Yugoslav Republic of", value: "MK"}, 
    { id: 130, label: "Madagascar", value: "MG"}, 
    { id: 131, label: "Malawi", value: "MW"}, 
    { id: 132, label: "Malaysia", value: "MY"}, 
    { id: 133, label: "Maldives", value: "MV"}, 
    { id: 134, label: "Mali", value: "ML"}, 
    { id: 135, label: "Malta", value: "MT"}, 
    { id: 136, label: "Marshall Islands", value: "MH"}, 
    { id: 137, label: "Martinique", value: "MQ"}, 
    { id: 138, label: "Mauritania", value: "MR"}, 
    { id: 139, label: "Mauritius", value: "MU"}, 
    { id: 140, label: "Mayotte", value: "YT"}, 
    { id: 141, label: "Mexico", value: "MX"}, 
    { id: 142, label: "Micronesia, Federated States of", value: "FM"}, 
    { id: 143, label: "Moldova, Republic of", value: "MD"}, 
    { id: 144, label: "Monaco", value: "MC"}, 
    { id: 145, label: "Mongolia", value: "MN"}, 
    { id: 146, label: "Montserrat", value: "MS"}, 
    { id: 147, label: "Morocco", value: "MA"}, 
    { id: 148, label: "Mozambique", value: "MZ"}, 
    { id: 149, label: "Myanmar", value: "MM"}, 
    { id: 150, label: "Namibia", value: "NA"}, 
    { id: 151, label: "Nauru", value: "NR"}, 
    { id: 152, label: "Nepal", value: "NP"}, 
    { id: 153, label: "Netherlands", value: "NL"}, 
    { id: 154, label: "Netherlands Antilles", value: "AN"}, 
    { id: 155, label: "New Caledonia", value: "NC"}, 
    { id: 156, label: "New Zealand", value: "NZ"}, 
    { id: 157, label: "Nicaragua", value: "NI"}, 
    { id: 158, label: "Niger", value: "NE"}, 
    { id: 159, label: "Nigeria", value: "NG"}, 
    { id: 160, label: "Niue", value: "NU"}, 
    { id: 161, label: "Norfolk Island", value: "NF"}, 
    { id: 162, label: "Northern Mariana Islands", value: "MP"}, 
    { id: 163, label: "Norway", value: "NO"}, 
    { id: 164, label: "Oman", value: "OM"}, 
    { id: 165, label: "Pakistan", value: "PK"}, 
    { id: 166, label: "Palau", value: "PW"}, 
    { id: 167, label: "Palestinian Territory, Occupied", value: "PS"}, 
    { id: 168, label: "Panama", value: "PA"}, 
    { id: 169, label: "Papua New Guinea", value: "PG"}, 
    { id: 170, label: "Paraguay", value: "PY"}, 
    { id: 171, label: "Peru", value: "PE"}, 
    { id: 172, label: "Philippines", value: "PH"}, 
    { id: 173, label: "Pitcairn", value: "PN"}, 
    { id: 174, label: "Poland", value: "PL"}, 
    { id: 175, label: "Portugal", value: "PT"}, 
    { id: 176, label: "Puerto Rico", value: "PR"}, 
    { id: 177, label: "Qatar", value: "QA"}, 
    { id: 178, label: "Reunion", value: "RE"}, 
    { id: 179, label: "Romania", value: "RO"}, 
    { id: 180, label: "Russian Federation", value: "RU"}, 
    { id: 181, label: "RWANDA", value: "RW"}, 
    { id: 182, label: "Saint Helena", value: "SH"}, 
    { id: 183, label: "Saint Kitts and Nevis", value: "KN"}, 
    { id: 184, label: "Saint Lucia", value: "LC"}, 
    { id: 185, label: "Saint Pierre and Miquelon", value: "PM"}, 
    { id: 186, label: "Saint Vincent and the Grenadines", value: "VC"}, 
    { id: 187, label: "Samoa", value: "WS"}, 
    { id: 188, label: "San Marino", value: "SM"}, 
    { id: 189, label: "Sao Tome and Principe", value: "ST"}, 
    { id: 190, label: "Saudi Arabia", value: "SA"}, 
    { id: 191, label: "Senegal", value: "SN"}, 
    { id: 192, label: "Serbia and Montenegro", value: "CS"}, 
    { id: 193, label: "Seychelles", value: "SC"}, 
    { id: 194, label: "Sierra Leone", value: "SL"}, 
    { id: 195, label: "Singapore", value: "SG"}, 
    { id: 196, label: "Slovakia", value: "SK"}, 
    { id: 197, label: "Slovenia", value: "SI"}, 
    { id: 198, label: "Solomon Islands", value: "SB"}, 
    { id: 199, label: "Somalia", value: "SO"}, 
    { id: 200, label: "South Africa", value: "ZA"}, 
    { id: 201, label: "South Georgia and the South Sandwich Islands", value: "GS"}, 
    { id: 202, label: "Spain", value: "ES"}, 
    { id: 203, label: "Sri Lanka", value: "LK"}, 
    { id: 204, label: "Sudan", value: "SD"}, 
    { id: 205, label: "Surilabel", value: "SR"}, 
    { id: 206, label: "Svalbard and Jan Mayen", value: "SJ"}, 
    { id: 207, label: "Swaziland", value: "SZ"}, 
    { id: 208, label: "Sweden", value: "SE"}, 
    { id: 209, label: "Switzerland", value: "CH"}, 
    { id: 210, label: "Syrian Arab Republic", value: "SY"}, 
    { id: 211, label: "Taiwan, Province of China", value: "TW"}, 
    { id: 212, label: "Tajikistan", value: "TJ"}, 
    { id: 213, label: "Tanzania, United Republic of", value: "TZ"}, 
    { id: 214, label: "Thailand", value: "TH"}, 
    { id: 215, label: "Timor-Leste", value: "TL"}, 
    { id: 216, label: "Togo", value: "TG"}, 
    { id: 217, label: "Tokelau", value: "TK"}, 
    { id: 218, label: "Tonga", value: "TO"}, 
    { id: 219, label: "Trinidad and Tobago", value: "TT"}, 
    { id: 220, label: "Tunisia", value: "TN"}, 
    { id: 221, label: "Turkey", value: "TR"}, 
    { id: 222, label: "Turkmenistan", value: "TM"}, 
    { id: 223, label: "Turks and Caicos Islands", value: "TC"}, 
    { id: 224, label: "Tuvalu", value: "TV"}, 
    { id: 225, label: "Uganda", value: "UG"}, 
    { id: 226, label: "Ukraine", value: "UA"}, 
    { id: 227, label: "United Arab Emirates", value: "AE"}, 
    { id: 228, label: "United Kingdom", value: "GB"}, 
    { id: 230, label: "United States Minor Outlying Islands", value: "UM"}, 
    { id: 231, label: "Uruguay", value: "UY"}, 
    { id: 232, label: "Uzbekistan", value: "UZ"}, 
    { id: 233, label: "Vanuatu", value: "VU"}, 
    { id: 234, label: "Venezuela", value: "VE"}, 
    { id: 235, label: "Viet Nam", value: "VN"}, 
    { id: 236, label: "Virgin Islands, British", value: "VG"}, 
    { id: 237, label: "Virgin Islands, U.S.", value: "VI"}, 
    { id: 238, label: "Wallis and Futuna", value: "WF"}, 
    { id: 239, label: "Western Sahara", value: "EH"}, 
    { id: 240, label: "Yemen", value: "YE"}, 
    { id: 241, label: "Zambia", value: "ZM"}, 
    { id: 242, label: "Zimbabwe", value: "ZW"} 
]
  
exports.VL_COUNTRY_OPTIONS = VL_COUNTRY_OPTIONS
exports.VL_LOCATION_OPTIONS = VL_LOCATION_OPTIONS
exports.VL_TIME_OF_DAY_OPTIONS = VL_TIME_OF_DAY_OPTIONS
