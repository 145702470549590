export const getStudent = /* GraphQL */ `
  query GetStudent($id: ID!) {
    getStudent(id: $id) {
      id
      address1
      address2
      city
      country
      dob
      education
      email
      ethnicity
      firstName
      gender
      income
      lastActivity
      lastLogin
      lastName
      middleName
      notes
      phoneHome
      phoneMobile
      phoneWork
      phoneWorkExt
      postal
      pronouns
      state
      status
      sub
      allStudents
      createdAt
      updatedAt
    }
  }
`;

export const studentSortFirstName = /* GraphQL */ `
  query StudentSortFirstName(
    $allStudents: Int!
    $firstName: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelStudentFilterInput
    $limit: Int
    $nextToken: String
  ) {
    studentSortFirstName(
      allStudents: $allStudents
      firstName: $firstName
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        dob
        email
        firstName
        lastActivity
        lastName
        notes
        phoneMobile
        postal
        pronouns
        status
      }
      nextToken
    }
  }
`;
export const studentSortLastName = /* GraphQL */ `
  query StudentSortLastName(
    $allStudents: Int!
    $lastName: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelStudentFilterInput
    $limit: Int
    $nextToken: String
  ) {
    studentSortLastName(
      allStudents: $allStudents
      lastName: $lastName
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        dob
        email
        firstName
        lastActivity
        lastName
        notes
        phoneMobile
        postal
        pronouns
        status
      }
      nextToken
    }
  }
`;
export const studentSortEmail = /* GraphQL */ `
  query StudentSortEmail(
    $allStudents: Int!
    $email: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelStudentFilterInput
    $limit: Int
    $nextToken: String
  ) {
    studentSortEmail(
      allStudents: $allStudents
      email: $email
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        dob
        email
        firstName
        lastActivity
        lastName
        notes
        phoneMobile
        postal
        pronouns
        status
      }
      nextToken
    }
  }
`;
export const studentSortDOB = /* GraphQL */ `
  query StudentSortDOB(
    $allStudents: Int!
    $dob: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelStudentFilterInput
    $limit: Int
    $nextToken: String
  ) {
    studentSortDOB(
      allStudents: $allStudents
      dob: $dob
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        dob
        email
        firstName
        lastActivity
        lastName
        notes
        phoneMobile
        postal
        pronouns
        status
      }
      nextToken
    }
  }
`;

export const searchStudents = /* GraphQL */ `
  query SearchStudents(
    $filter: SearchableStudentFilterInput
    $sort: [SearchableStudentSortInput]
    $limit: Int
    $nextToken: String
    $from: Int
    $aggregates: [SearchableStudentAggregationInput]
  ) {
    searchStudents(
      filter: $filter
      sort: $sort
      limit: $limit
      nextToken: $nextToken
      from: $from
      aggregates: $aggregates
    ) {
      items {
		id
        dob
        email
        firstName
        lastActivity
        lastName
        notes
        phoneMobile
        postal
        pronouns
        status
      }
      nextToken
      total
      aggregateItems {
        name
        result {
          ... on SearchableAggregateScalarResult {
            value
          }
          ... on SearchableAggregateBucketResult {
            buckets {
              key
              doc_count
            }
          }
        }
      }
    }
  }
`;